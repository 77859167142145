import {SocketIOClient} from '../lib/socket-io/client/socket-io-client'
import {WaitForBeTrue,sleep} from '../lib/util/time-date'
var dateFormat = require('dateformat');

interface SocketIOClientHeader{
    contentType:string,
    timestamp:Date,
    from:string,
}

enum SocketIOClientContentType{
    TestConnecion = 'TestConnecion',
    SendMsg = 'SendMsg',
    EditConfig_Local = 'EditConfig_Local',
    EditConfig_IO = 'EditConfig_IO',
    EditConfig_StateMachine = 'EditConfig_StateMachine',
}
export class SocketIO{
    public local_room_name:string = 'home_sense_local_room';
    private local: boolean = true;
    private socket:SocketIOClient | undefined;

    private lastArrivedFromMeList:Array<{head:SocketIOSendHeaderModel,payload:any[]}> = []; //Enviados por mim para mim mesmi
    private lastArrivedInResponseToMeList:Array<{head:SocketIOSendHeaderModel,payload:any[]}> = []; //Enviados em resposta a mim
    //private lastArrivedFromOthersList:Array<{head:SocketIOSendHeaderModel,payload:any[]}> = []; //Menssagens de outros usuarios

    private removePayloadTimer: any;

    constructor(
        public OnResponseRefusedError:(ResponseRefuseReason:string)=>void, //Erros de usuarios/autenticação. Podemos mostrar msg de usuario incorreto..
        public OnExternalMessagesReceived:(head:SocketIOSendHeaderModel,payload:any[])=>void,
        public OnResponseWaiting:()=>void, //Ao aguardar resposta(pode ser usado para animar uma bara de loading)
        public OnResponseArrived:()=>void, //Ao chegar resposta (pode ser usado para animar uma bara de loading)
        public OnResponseError:()=>void, //Pode ser utilizado para mostrar um msg de erro de conexão.
        public OnUserCredentialsError:()=>void, //Senha ou usuario não encontrado/incorretos.
        public OnUserWithoutRightsError:()=>void, //Usuario não autrizado

        local:boolean,
        public local_ip:string,public local_port:number,
        public remote_ip:string, public remote_port:number,
        //public account_email:string,
        public remote_room_name:string,
        public user_name:string,public user_pwd:string){
            
        this.config(/* local, */local_ip,local_port,remote_ip,remote_port,remote_room_name,user_name,user_pwd)
    }

    private removeOlderFromOtherPayloads(){
        clearInterval(this.removePayloadTimer);
        //Vai removendo elementos antigos da fila de payloads recebidos:
        this.removePayloadTimer = setInterval(() => {
            let removed = this.lastArrivedInResponseToMeList.shift();
            //console.log('List:',this.lastArrivedInResponseToMeList)
            //Se a lista ja esta vazia, então zera o handler:
            if(this.lastArrivedInResponseToMeList.length == 0){clearInterval(this.removePayloadTimer);}
        }, 3000);
    }
    
    async setLocal(){
        this.local = true;
        this.socket?.leave(this.remote_room_name);
        this.socket?.disconnect(true);
        this.socket = undefined;

        this.socket = new SocketIOClient(this.local_ip,this.local_port,this.user_name);
    
        await this.socket.connect((id,user,...data)=>{
            console.log('Broadcast content arrived:',...data,`(From user '${user}' with socket id '${id}')`)
        });
    
        this.socket.join(this.local_room_name,(id,user,...data)=>{
            console.log(...data,`(From user '${user}' with socket id '${id}')`);

            let head:SocketIOSendHeaderModel;let payload:any;
            [head,...payload] = data;
            if(head.from.email == this.user_name && !head.responseHeader?.inResponseToUser)
            {this.lastArrivedFromMeList.push({head,payload});}
            else if(head.from.email == this.user_name && head.responseHeader?.inResponseToUser)
            {this.lastArrivedInResponseToMeList.push({head,payload});}
            else{this.OnExternalMessagesReceived(head,payload)}
            this.removeOlderFromOtherPayloads();
        },true)
    }
    async setRemote(){
        this.local = false;
        this.socket?.leave(this.local_room_name);
        this.socket?.disconnect(true);
        this.socket = undefined;

        this.socket = new SocketIOClient(this.remote_ip,this.remote_port,this.user_name);
    
        await this.socket.connect((id,user,...data)=>{
            console.log('Broadcast content arrived:',...data,`(From user '${user}' with socket id '${id}')`)
        });
    
        this.socket.join(this.remote_room_name,(id,user,...data)=>{
            console.log(...data,`(From user '${user}' with socket id '${id}')`);

            let head:SocketIOSendHeaderModel;let payload:any;
            [head,...payload] = data;
            if(head.from.email == this.user_name && !head.responseHeader?.inResponseToUser)
            {this.lastArrivedFromMeList.push({head,payload});}
            else if(head.from.email == this.user_name && head.responseHeader?.inResponseToUser)
            {this.lastArrivedInResponseToMeList.push({head,payload});}
            else{this.OnExternalMessagesReceived(head,payload)}
            this.removeOlderFromOtherPayloads();
        },true)
    }
    async config(/* local:boolean, */
        local_ip:string,local_port:number,
        remote_ip:string, remote_port:number,
        //account_email:string,
        remote_room_name:string,
        user_name:string,user_pwd:string){
        
        this.local_ip = local_ip;
        this.local_port = local_port;
        this.remote_ip = remote_ip;
        this.remote_port = remote_port;

        //this.account_email = account_email;
        this.remote_room_name = remote_room_name;
        this.user_name = user_name;
        this.user_pwd = user_pwd;

        if (this.local) {
            this.setLocal();
        } else {
            this.setRemote();
        }
    }

    reconnect(){
        if (this.local) {
            this.setLocal();
        } else {
            this.setRemote();
        }
    }
    /**
     * Send a message
     *
     * @param {string} contentType - The msg content type
     * @param {string} payload     - The msg payload
     * @returns {void}
     */
    private async send(contentType:string,...payload:any){
        let header:SocketIOSendHeaderModel = {
            contentType,
            timestamp:this.user_name+'/'+this.socket?.socket?.id+'/'+dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss-l"),
            time:dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"),
            from:{email:this.user_name,pwd:this.user_pwd}
        }
        
        if (this.local) {
            this.socket?.send(this.local_room_name,header,...payload);
        } else {
            this.socket?.send(this.remote_room_name,header,...payload);
        }
        return header;
    }
    /**
     * Send/write a message and wait for listen its content back
     *
     * @param {string} contentType - The msg content type
     * @param {string} payload     - The msg payload
     * @returns {void}
     */
    async write(contentType:string,...payload:any):Promise<SocketIOSendHeaderModel | undefined>{
        //O 'header' tem um valor unico para cada chamada de '.write'
        let header:SocketIOSendHeaderModel = await this.send(contentType,...payload)
        let x = this.lastArrivedFromMeList.length
        //console.log('before','n is:',x,"head is:",this.lastArrivedList)
        try {
            
            await WaitForBeTrue(()=>{
                //console.log('after','n is:',x,"head is:",this.lastArrivedList)
                let found = false;
                this.lastArrivedFromMeList = this.lastArrivedFromMeList.filter((val,index,arr)=>{
                    if(header.timestamp == val.head.timestamp) {found = true;}
                    if(header.timestamp !== val.head.timestamp) {return true};
                })
                return found;
            },50,3000)
            //console.log('write ok',header?.timestamp,this.lastArrivedList)
            return header;
        } catch (error) {
            //console.log('write nok',header?.timestamp,this.lastArrivedList)

            this.lastArrivedFromMeList = this.lastArrivedFromMeList.filter((val,index,arr)=>{
                if(header.timestamp !== val.head.timestamp) return true;
            })
            this.reconnect();
            return undefined;
        }
    }
    /**
     * Write a 'content-type' and Wait for a specific 'content-type' msg arrives
     *
     * @param {string} contentTypeToSend - The msg content type to write/send
     * @param {string} contentTypeToWait - The msg content type to wait for
     * @param {string} payload - The payload to send
     * @param {void} return - Returns the last payload for specific content type, or throw an exception if any error occurs
     */
    async waitFor(contentTypeToSend:string,contentTypeToWait:string,...payload:any):Promise<any>{
        this.OnResponseWaiting();
        //this.OnUserCredentialError();
        let written_head = await this.write(contentTypeToSend,payload)
        if(!written_head){
            console.warn('Problems with socket connection. Trying again...')
            //Tentamos mais uma vez antes de registrar o erro:
            await sleep(3000);
            written_head = await this.write(contentTypeToSend,payload)
            if(!written_head){this.OnResponseError();throw 'socket.io.ts(waitFor - Error on "write".)'}
        }
        
        let _payload = null;
        let _head:SocketIOSendHeaderModel | undefined;
        try {
            await WaitForBeTrue(()=>{
                let found = false;
                let ok = this.lastArrivedInResponseToMeList.some((val,index,arr)=>{
                    if(contentTypeToWait == val.head.contentType && val.head.timestamp == written_head?.timestamp) {found = true;_payload=val.payload;_head = val.head}
                })
                return found;
            },50,3000)
            //console.log('write ok')

            if (_head?.responseHeader?.refuse_reason){throw _head.responseHeader?.refuse_reason}
            this.OnResponseArrived();
            return _payload;
        } catch (error) {
            //console.log('write nok')
            if (error) {
                switch (error) {
                    case ResponseRefuseReasonEnum.USER_CREDENTIALS:
                        this.OnUserCredentialsError();
                        break;
                    case ResponseRefuseReasonEnum.USER_WITHOUT_RIGHTS:
                        this.OnUserWithoutRightsError();
                        break;
                    default:
                        this.OnResponseError();
                        break;
                }
            }
            else{
                this.OnResponseError();
            }
            throw 'socket.io.ts(waitFor - Error on "WaitForBeTrue".)'
        }
    }
    /**
     * Write a 'content-type' and Wait for the same 'content-type' msg arrives
     *
     * @param {string} contentType - The msg content type to write/send and to wait for
     * @param {string} payload - The payload to send
     * @param {void} return - Returns the last payload for specific content type, or throw an exception if any error occurs
     */
    async wait(contentType:string,...payload:any):Promise<any>{
        try {
            return await this.waitFor(contentType,contentType,payload);
        } catch (error) {
            throw 'socket.io.ts(wait - Error on "waitFor".)'
        }
    }
}

export interface SocketIOSendHeaderModel{
    contentType:string,
    timestamp:string,
    time:string,
    from:UserModel,
    //inResponseToUser?:boolean, //Msg esta sendo enviada do local-server 'em resposta a' alguma pessoa!
    responseHeader?:SocketIOResponseHeaderModel,
}
export interface SocketIOResponseHeaderModel{
    header?:SocketIOSendHeaderModel,
    refuse_reason?:string; //Razão da requisição ter sido rejeitada(ResponseRefuseReasonModel)
    inResponseToUser?:boolean, //Msg esta sendo enviada do local-server 'em resposta a' alguma pessoa!
    args?:Array<any>,
}
export enum ResponseRefuseReasonEnum{
    USER_CREDENTIALS="USER_CREDENTIALS", //Nome ou senha do usuário incorretos
    USER_WITHOUT_RIGHTS="USER_WITHOUT_RIGHTS", //Usuário sem autorização
}
export interface UserModel{
    email: string,
    pwd: string,
}