import React from 'react';
import { IonToast,IonLabel,IonSelect,IonSelectOption,IonItem } from '@ionic/react';
//import {Styled_ItemDivider} from './styles'
import styled from 'styled-components';


interface IComponentProps_ItemSelect {
  label:string;
  value:number;
  placeholder?:string;
  list:Array<string>,

  onChange?:(index:number) => void;
  children?: React.ReactNode;
}

const ItemSelectStyled = styled.div`

  `;
export const ItemSelect = (props:IComponentProps_ItemSelect) => {
  return (
      <ItemSelectStyled>

        <IonItem>
          <IonLabel>{props.label}</IonLabel>
          <IonSelect
          value={props.value}
          placeholder={props.placeholder}
          onIonChange={(e)=>{props.onChange != null && props.onChange(e.detail.value)}}
          >
            {
              props.list.map((val,index,arr)=>{
                return(
                  <IonSelectOption key={index} value={index}>{val}</IonSelectOption>
                )
              })
            }
          </IonSelect>
        </IonItem>
      </ItemSelectStyled>
    );
}