import React from 'react';
import { IonChip, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab1.css';
import { IonItemDivider,IonSelect,IonSelectOption,IonToggle,IonInput,IonCheckbox,IonRange,IonDatetime,IonList,IonText,IonAvatar,IonThumbnail,IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton } from '@ionic/react';
import { star,closeCircle,informationCircle,checkmarkCircle,shuffle,home,navigate,pin, wifi, wine, warning, walk } from 'ionicons/icons';

import {ExempleFull} from '../components/_exemple/exemple'

const Tab1: React.FC = () => {
  return (
    // <IonPage>
    //   {/* <IonContent color="dark"> */}
    //   <IonContent>
    //     <IonHeader>
    //       <IonToolbar>
    //         <IonTitle>Tab 1</IonTitle>
    //       </IonToolbar>
    //     </IonHeader>
        
    //     <Abc></Abc>
    //     <ExploreContainer name="Tab 1 page" />
    //   </IonContent>
    // </IonPage>
    // <CardExamples/>

    // <ItemDividerExample></ItemDividerExample>
    <>
    
    <ExempleFull></ExempleFull>
    </>
    
  );
};

export default Tab1;