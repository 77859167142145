//Se voce não quer utilizar o storage como um componente react, então esta classe com metodos estaticos é
//para voce!
import * as model from  './model'

export class Storage{
    private static key: string = 'default_name';

    public static setKeyName(keyName: string){
        this.key = keyName;
    }
    public static getKeyName(){
        return this.key;
    }

    public static read(): model.dataModel {
        const item = window.localStorage.getItem(this.key);
        return item ? JSON.parse(item) : undefined;
    }

    public static write(value: model.dataModel) {
        try {
            window.localStorage.setItem(this.key, JSON.stringify(value));
        } catch (error) {
            console.warn('Storage.write')
        }
    }
    public static clearSession(){
        try {
            this.write({db:this.read().db,preferences:this.read().preferences,boardList:this.read().boardList,session:{}})
        } catch (error) {
            console.error('Storage.clearSession!')
        }
    }
}