
import * as model from '../model';
import {socket,Storage} from '../../../resources/app/resource.instance';
import {OutputI2cModulesModel} from '../../../resources/app/imported-from-nodejs-local-server-models'

export enum SocketIORequestType_Hardware{
    GetLowDB_Hardware_OutputI2cModules='GetLowDB_Hardware_OutputI2cModules',
    SetLowDB_Hardware_OutputI2cModules='SetLowDB_Hardware_OutputI2cModules',

    GetLowDB_Hardware_InputI2cModules='GetLowDB_Hardware_InputI2cModules',
    SetLowDB_Hardware_InputI2cModules='SetLowDB_Hardware_InputI2cModules',

    GetLowDB_Hardware_InputDigitalList='GetLowDB_Hardware_InputDigitalList',
    SetLowDB_Hardware_InputDigitalList='SetLowDB_Hardware_InputDigitalList',

    GetLowDB_Hardware_OutputDigitalList='GetLowDB_Hardware_OutputDigitalList',
    SetLowDB_Hardware_OutputDigitalList='SetLowDB_Hardware_OutputDigitalList',
}

export class HardwareDBStorage{
    public static async GetOutputI2cModules(online?:boolean):Promise<model.db_hardware_OutputI2cModulesModel>{
        try {
            if (online) {
                let actual = Storage.read();
                let [data] = await socket.waitFor(SocketIORequestType_Hardware.GetLowDB_Hardware_OutputI2cModules,SocketIORequestType_Hardware.GetLowDB_Hardware_OutputI2cModules,null)
                Storage.write({...actual,db:{...actual.db,hardware:{...actual.db.hardware,OutputI2cModulesModel:{List:data}}}});
                actual = Storage.read();
                return Storage.read().db.hardware.OutputI2cModulesModel;
            } else {
                return Storage.read().db.hardware.OutputI2cModulesModel;
            }
        } catch (error) {
            throw 'Error at "storage.hardware.db->HardwareDBStorage.GetOutputI2cModules" on get data.'
        }
    }
    public static async SetOutputI2cModules(online:boolean,OutputI2cModulesModel:model.db_hardware_OutputI2cModulesModel):Promise<model.db_hardware_OutputI2cModulesModel>{
        try {
            if (online) {
                let [data] = await socket.waitFor(SocketIORequestType_Hardware.SetLowDB_Hardware_OutputI2cModules,SocketIORequestType_Hardware.SetLowDB_Hardware_OutputI2cModules,OutputI2cModulesModel.List)
                Storage.write({...Storage.read(),db:{...Storage.read().db,hardware:{...Storage.read().db.hardware,OutputI2cModulesModel:{List:data}}}})
                return Storage.read().db.hardware.OutputI2cModulesModel;
            } else {
                Storage.write({...Storage.read(),db:{...Storage.read().db,hardware:{...Storage.read().db.hardware,OutputI2cModulesModel:{List:OutputI2cModulesModel.List}}}});
                return Storage.read().db.hardware.OutputI2cModulesModel;
            }
        } catch (error) {
            throw 'Error at "storage.hardware.db->HardwareDBStorage.SetOutputI2cModules" on get data.'
        }
    }

    public static async GetInputI2cModules(online?:boolean):Promise<model.db_hardware_InputI2cModulesModel>{
        try {
            if (online) {
                let actual = Storage.read();
                let [data] = await socket.waitFor(SocketIORequestType_Hardware.GetLowDB_Hardware_InputI2cModules,SocketIORequestType_Hardware.GetLowDB_Hardware_InputI2cModules,null)
                Storage.write({...actual,db:{...actual.db,hardware:{...actual.db.hardware,InputI2cModulesModel:{List:data}}}});
                actual = Storage.read();
                return Storage.read().db.hardware.InputI2cModulesModel;
            } else {
                return Storage.read().db.hardware.InputI2cModulesModel;
            }
        } catch (error) {
            throw 'Error at "storage.hardware.db->HardwareDBStorage.GetInputI2cModules" on get data.'
        }
    }
    public static async SetInputI2cModules(online:boolean,InputI2cModulesModel:model.db_hardware_InputI2cModulesModel):Promise<model.db_hardware_InputI2cModulesModel>{
        try {
            if (online) {
                let [data] = await socket.waitFor(SocketIORequestType_Hardware.SetLowDB_Hardware_InputI2cModules,SocketIORequestType_Hardware.SetLowDB_Hardware_InputI2cModules,InputI2cModulesModel.List)
                Storage.write({...Storage.read(),db:{...Storage.read().db,hardware:{...Storage.read().db.hardware,InputI2cModulesModel:{List:data}}}})
                return Storage.read().db.hardware.InputI2cModulesModel;
            } else {
                Storage.write({...Storage.read(),db:{...Storage.read().db,hardware:{...Storage.read().db.hardware,InputI2cModulesModel:{List:InputI2cModulesModel.List}}}});
                return Storage.read().db.hardware.InputI2cModulesModel;
            }
        } catch (error) {
            throw 'Error at "storage.hardware.db->HardwareDBStorage.SetInputI2cModules" on get data.'
        }
    }

    public static async GetOutputDigitalList(online?:boolean):Promise<model.db_hardware_OutputDigitalListModel>{
        try {
            if (online) {
                let actual = Storage.read();
                let [data] = await socket.waitFor(SocketIORequestType_Hardware.GetLowDB_Hardware_OutputDigitalList,SocketIORequestType_Hardware.GetLowDB_Hardware_OutputDigitalList,null)
                Storage.write({...actual,db:{...actual.db,hardware:{...actual.db.hardware,OutputDigitalListModel:{List:data}}}});
                actual = Storage.read();
                return Storage.read().db.hardware.OutputDigitalListModel;
            } else {
                return Storage.read().db.hardware.OutputDigitalListModel;
            }
        } catch (error) {
            throw 'Error at "storage.hardware.db->HardwareDBStorage.GetOutputDigitalList" on get data.'
        }
    }
    public static async SetOutputDigitalList(online:boolean,OutputDigitalList:model.db_hardware_OutputDigitalListModel):Promise<model.db_hardware_OutputDigitalListModel>{
        try {
            if (online) {
                let [data] = await socket.waitFor(SocketIORequestType_Hardware.SetLowDB_Hardware_OutputDigitalList,SocketIORequestType_Hardware.SetLowDB_Hardware_OutputDigitalList,OutputDigitalList.List)
                Storage.write({...Storage.read(),db:{...Storage.read().db,hardware:{...Storage.read().db.hardware,OutputDigitalListModel:{List:data}}}})
                return Storage.read().db.hardware.OutputDigitalListModel;
            } else {
                Storage.write({...Storage.read(),db:{...Storage.read().db,hardware:{...Storage.read().db.hardware,OutputDigitalListModel:{List:OutputDigitalList.List}}}});
                return Storage.read().db.hardware.OutputDigitalListModel;
            }
        } catch (error) {
            throw 'Error at "storage.hardware.db->HardwareDBStorage.SetOutputDigitalList" on get data.'
        }
    }
}