
import {UserModel} from '../../resources/app/imported-from-nodejs-local-server-models'
import {LocalModel} from '../../resources/app/imported-from-nodejs-local-server-models'
import {OutputI2cModulesModel,InputI2cModulesModel,OutputDigitalListModel,InputDigitalListModel} from '../../resources/app/imported-from-nodejs-local-server-models'

export class model{
    static get_default_db(name:string):dbModel{
        return {
            BoardName:name,
            machinestate:{abc:'111',xyz:'222'},
            hardware:{OutputI2cModulesModel:{List:[]},InputI2cModulesModel:{List:[]},OutputDigitalListModel:{List:[]},InputDigitalListModel:{List:[]}},
            locations:{List:[]},
            local:{remote_roomName_hashSecret:'homesense_hash/raspberry-hash-code-here',isLocal:true,user:{email:'user1',pwd:'user1'},network:{local_ip:'192.168.0.14',local_port:3010,remote_ip:'34.82.220.34',remote_port:4000}},
            user:{List:[]},
        }
    }
    static data_get_default(){
        let db: dataModel  = {
            db:this.get_default_db('Casa'),
            preferences:{BoardIndex:0},
            boardList:[this.get_default_db('Casa'),this.get_default_db('Casa da praia'),this.get_default_db('Casa do sitio'),this.get_default_db('Casa do pai'),this.get_default_db('Casa da sogra')],
            session:{},
        }
        return db;
    }
    static data_get_fakeData(){
        let db: dataModel  = {
            db:this.get_default_db('Casa'),
            preferences:{BoardIndex:0},
            boardList:[this.get_default_db('Casa'),this.get_default_db('Casa da praia'),this.get_default_db('Casa do sitio'),this.get_default_db('Casa do pai'),this.get_default_db('Casa da sogra')],
            session:{},
        }
        return db;
    }
}

export interface dataModel{
    db:dbModel,
    preferences:PreferencesModel,
    boardList:Array<dbModel>,
    session:{
        x?:string,
        OnLoadedAtTimestamp?:string,
        OnRemoteChangesTimestamp?:string,
        OnNetworkErrorTimestamp?:string,
    },
}

export interface dbModel{
    BoardName:string,
    machinestate: db_machinestate,
    hardware: db_hardware,
    locations:db_locations,
    local: db_local,
    user: db_users,
}

export interface db_machinestate{
    abc: string,
    xyz: string,
}
export interface db_hardware{
    OutputI2cModulesModel: db_hardware_OutputI2cModulesModel,
    InputI2cModulesModel: db_hardware_InputI2cModulesModel,
    OutputDigitalListModel:db_hardware_OutputDigitalListModel,
    InputDigitalListModel:db_hardware_InputDigitalListModel,
    //Local:Array<LocalModel>,
}
export interface db_hardware_OutputI2cModulesModel{
    List:Array<OutputI2cModulesModel>,
}
export interface db_hardware_InputI2cModulesModel{
    List:Array<InputI2cModulesModel>,
}
export interface db_hardware_OutputDigitalListModel{
    List:Array<OutputDigitalListModel>,
}
export interface db_hardware_InputDigitalListModel{
    List:Array<InputDigitalListModel>,
}
export interface db_users{
    List:Array<UserModel>,
}
export interface db_locations{
    List:Array<LocalModel>,
}
export interface db_local{
    isLocal: boolean,
    user: User,
    network: Network,
    remote_roomName_hashSecret: string, //LOCAL_SECRET_HASH
}
export interface User{
    email: string,
    pwd: string,
}

export interface Network{
    local_ip: string,
    local_port: number,
    remote_ip: string,
    remote_port: number,
}
interface PreferencesModel{
    BoardIndex:number,
}