import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../storage/storage.react';
import { Storage } from '../storage/storage';

import { IonGrid,IonRow,IonCol,IonMenuButton,IonBackButton,IonButtons,IonToast,IonSpinner,IonSkeletonText,IonProgressBar,IonLoading,IonListHeader,IonModal,IonImg,IonThumbnail,IonButton,IonTextarea,IonCheckbox,IonRadio,IonToggle,IonInput,IonPage,IonHeader,IonToolbar,IonTitle,IonContent } from '@ionic/react';
//import { heart,trash,star,archive } from 'ionicons/icons';
import { ItemSlidingNotifications,ItemDivider,Item,ItemSliding} from '../../components/ionic/item/item'
import { Input,Textarea} from '../../components/ionic/input/input'
import {Fab} from '../../components/ionic/fab/fab'
import {Picker} from '../../components/ionic/picker/picker'
import {Avatar,AvatarChip,AvatarItem,ImageItem,ThumbnailItem,Thumbnail,Image} from '../ionic/Image/image'
import {AlertOk,AlertOkCancel,Loading,Toast} from '../../components/ionic/alert/alert'
import {ToggleItem} from '../../components/ionic/button/button'

import { IonReorderGroup,IonReorder,IonAlert,IonRadioGroup,IonPopover,IonChip,IonAvatar,IonPicker,IonFabButton,IonFab,IonFabList,IonIcon,IonNote,IonItemSliding,IonItemOptions,IonItemOption,IonItemDivider,IonList, IonItem, IonLabel } from '@ionic/react';
import { call,star,pizza,logoInstagram,logoFacebook,logoVimeo,settings,person,share,arrowUpCircle,arrowBackCircle,add,arrowForwardCircle,heart,trash,archive } from 'ionicons/icons';
import styled from 'styled-components';
import {Swipeable} from 'react-swipeable'
import {ReactComponent as ReactLogo} from './alert.svg';
import { ItemReorderEventDetail } from '@ionic/core';
import {socket} from '../../resources/app/resource.instance'

import {LocalDBStorage} from '../storage/CRUD/storage.local.db'

export const ExempleFull : React.FC = () => {
    return (
        <Swipeable onSwipedRight={() => alert("Direita")} onSwipedLeft={() => alert("Esquerda")}>
        <IonPage>
            <IonHeader>
                <IonToolbar>
                <IonTitle>Test Component</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
            scrollEvents={true}
            onIonScrollStart={() => {}}
            onIonScroll={() => {}}
            onIonScrollEnd={() => {}}>
                <Exemple020/>
                <CallTest active={true}/>
            </IonContent>
        </IonPage>
        </Swipeable>
      );
  }

const CallTest = (props:{active:boolean}) => {
    /* 
    Test Caller
    */
    return (
        <>
        {
            props.active? (
                <Test></Test>
            ) : (<div></div>)
        }
        
        </>
    );
}

const Test = ():JSX.Element => {
    /* 
    Test:
    Teste temporario
    */
   //@ts-ignore
   const { state, dispatch } = useContext(AppContext);
    
   
  return (
    <>    
    </>
  );
}

export const Exemple_Template : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-item' de varios tipos
    */
    return (
        <>

        </>
    );
}

export const Exemple001 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-item' de varios tipos
    */
    return (
    <>
        <ItemDivider title="Exemple - 001" color="danger"></ItemDivider>

        <ItemDivider title="Titulo com cor" color="tertiary"></ItemDivider>
        <ItemDivider title="Titulo simples" click={()=>{alert('Fui clicado!')}}></ItemDivider>
        <Item click={()=>{alert('Fui clicado!')}} title="Item simples"></Item>
        <Item title="Item botão" button={true} detail={true}></Item>
        <Item title="Item botão" button={true} ></Item>
        <Item title="Item botão desabilitado" button={true} disabled={true}></Item>
        </>
    );
}

export const Exemple002 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-item' de varios tipos
    */
    return (
        <>
            <ItemDivider title="Exemple - 002" color="danger"></ItemDivider>
            <ItemSliding title="Sem Sliding"></ItemSliding>
            <ItemSliding options_start={[
                {icon_slot:'icon-only',icon:trash,title:'Delete'},
                {icon_slot:'icon-only',icon:archive,title:'Arquivar'}
                ]} title="Com Sliding na frente">
            </ItemSliding>
            <ItemSliding options_start={[{icon_slot:'icon-only',icon:trash,title:'Delete'}]} options_end={[{icon_slot:'icon-only',icon:heart,title:'Favorito'}]} title="Com Sliding ambos os lados"></ItemSliding>
            <ItemSlidingNotifications options_start={[{icon_slot:'icon-only',icon:trash,title:'Delete'}]} options_end={[{icon_slot:'icon-only',icon:heart,title:'Favorito'}]} title="Nova notificação" description="Descrição.." detail="Detalhes aqui" time="22:35"></ItemSlidingNotifications>
        </>
    );
}

export const Exemple003 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-input' de varios tipos
    */
   const [text, setText] = useState<string>();
   const [number, setNumber] = useState<number>();
    return (
        <IonList>
            <ItemDivider title="Exemple - 003 (ion-input)" color="danger"></ItemDivider>

            <Input keyboard={(key)=>{alert(key)}} value={text!} change={(data)=>{setText(data)}}></Input>
            <Input disabled={true} value={text!} change={(data)=>{setText(data)}}></Input>
            <Input value={text!} placeholder='name here' label="Titulo campo" label_position="stacked"></Input>
            <Input label='Date' type="date" value={text!} change={(data)=>{setText(data)}}></Input>
            <Input label='Time' type="time" value={text!} change={(data)=>{setText(data)}}></Input>
            <Input inputmode='email' label='Email' type="email" value={text!} change={(data)=>{setText(data)}}></Input>
            <Input keyboard={(key)=>{console.log(key)}} type='number' value={number!} change={(data)=>{setNumber(parseInt(data!, 10))}}></Input>
        </IonList>
    );
}

export const Exemple004 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-textarea' de varios tipos
    */
   const [text, setText] = useState<string>();
   const [number, setNumber] = useState<number>();
    return (
        <IonList>
            <ItemDivider title="Exemple - 004 (ion-textarea)" color="danger"></ItemDivider>

            <Textarea keyboard={(key)=>{alert(key)}} value={text!} change={(data)=>{setText(data)}}></Textarea>
            <Textarea placeholder='5 rows here..' rows={5} keyboard={(key)=>{alert(key)}} value={text!} change={(data)=>{setText(data)}}></Textarea>
            <Textarea disabled={true} value={text!} change={(data)=>{setText(data)}}></Textarea>
            <Textarea value={text!} placeholder='stacked..' label="Stacked" label_position="stacked"></Textarea>
            <Textarea value={text!} label="Floating" label_position="floating"></Textarea>
            <Textarea keyDown={(keyCode)=>{alert(keyCode)}} value={text!} placeholder='fixed..' label="Fixed" label_position="fixed"></Textarea>
        </IonList>
    );
}
export const Exemple005 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-textarea' de varios tipos
    */
    return (
        //,logoInstagram,logoFacebook,logoVimeo,settings,person,share
        <>
            <ItemDivider title="Exemple - 005 (ion-fab)" color="danger"></ItemDivider>

            <Fab vertical="top" horizontal="start" icon={logoVimeo}></Fab>

            <Fab vertical="bottom" horizontal="start" icon={logoVimeo}
            click={()=>{alert('bottom/start clicked!')}}></Fab>
            
            <Fab vertical="top" horizontal="end" icon={logoInstagram} side="start" edge={true}
            fabButtons={[{icon:logoInstagram,click:()=>{alert('FabButton clicled!')}}]}></Fab>

            <Fab vertical="bottom" horizontal="end" icon={share} side="top"
            fabButtons={[
                {icon:logoFacebook,click:()=>{alert('FabButton clicled!')}},
                {icon:settings,click:()=>{alert('FabButton clicled!')}},
                {icon:person,click:()=>{alert('FabButton clicled!')}},
                ]}></Fab>
        </>
    );
}

export const Exemple006 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-textarea' de varios tipos
    */
    const [isOpen, setIsOpen] = useState<boolean>();
    return (
        //,logoInstagram,logoFacebook,logoVimeo,settings,person,share
        <>
            <ItemDivider title="Exemple - 006 (ion-picker)" color="danger"></ItemDivider>

            <Fab click={()=>{setIsOpen(true)}} vertical="top" horizontal="start" icon={add}></Fab>
            <Picker
            onCancel={()=>{setIsOpen(false);alert('Cancelado!')}}
            onSave={(val)=>{
                setIsOpen(false);alert('Salvo!');
                console.log(val);
                console.log(val["Animais"]);}}
            cancelButtonText='Cancelar'
            okButtonText='Confirmar'
            isOpen={isOpen || false}
            pickerColumns={[
                {name:"Animais",options:[{text:'Gato',value:'1'},{text:'vaca',value:'2'}]},
                {name:"Cidades",options:[{text:'Jlle',value:'1'},{text:'Floripa',value:'2'},{text:'Blumenal',value:'3'}]},
                {name:"Navegar",options:[{text:'Chrome',value:'1'},{text:'Mozila',value:'2'}]},
            ]}
            ></Picker>
        </>
    );
}

export const Exemple007 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-avatar' de varios tipos
    */
    return (
        //,logoInstagram,logoFacebook,logoVimeo,settings,person,share
        <>
            <ItemDivider title="Exemple - 007 (ion-avatar)" color="danger"></ItemDivider>

            <AvatarItem title="Willyan" comment="willyan_fidelis@hotmail.com" img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb" slot="start"></AvatarItem>
            <AvatarItem title="Willyan" comment="willyan_fidelis@hotmail.com" img="https://scontent.fjoi2-1.fna.fbcdn.net/v/t1.0-9/46717218_2238497662829699_7046670606808383488_n.jpg?_nc_cat=105&_nc_sid=85a577&_nc_eui2=AeF7iNno0quai-Rn0A0KPIg8xz5esRj9EUvHPl6xGP0RSwgIC-C8NROz10sp9k8EqIGPmogYa0CfYmCrxfm3NTPP&_nc_ohc=a_pU9I1qwRIAX8pz1Ut&_nc_ht=scontent.fjoi2-1.fna&oh=4331c8f669de9f811fbb3e33164f3e2a&oe=5EB5E17A" slot="start"></AvatarItem>

            <AvatarItem title="Avatar" comment="avatar@hotmail.com" img="https://img.olhardigital.com.br/uploads/acervo_imagens/2019/05/r16x9/20190507051851_1200_675_-_avatar.jpg" slot="end"></AvatarItem>
            <AvatarItem title="Avatar" comment="avatar@hotmail.com" img="https://img.olhardigital.com.br/uploads/acervo_imagens/2019/05/r16x9/20190507051851_1200_675_-_avatar.jpg" slot="end"></AvatarItem>

            <Avatar img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb"></Avatar>
            <AvatarChip title="Willyan" img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb"></AvatarChip>
        </>
    );
}

export const Exemple008 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-avatar' de varios tipos
    */
   const Styled = styled.div`
    #x {
        width:80px;
        height:80px;
        color:var(--ion-color-primary, red)
    };
    `;
   type Item = {
    src: string;
    text: string;
  };
  const items: Item[] = [
      { src: 'http://placekitten.com/g/200/300', text: 'a picture of a cat' },
      { src: 'https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb', text: 'a picture of a willyan' },
      { src: 'http://placekitten.com/g/200/300', text: 'a picture of a cat' },
    ];
    return (
        <>
            <ItemDivider title="Exemple - 008 (ion-img)" color="danger"></ItemDivider>

            {items.map((image, i, arr) => (
            <ImageItem key={i} img={image.src} slot="start" title={image.text}></ImageItem>
        ))}
        

        <ImageItem img="https://scontent.fjoi2-1.fna.fbcdn.net/v/t1.0-9/46717218_2238497662829699_7046670606808383488_n.jpg?_nc_cat=105&_nc_sid=85a577&_nc_eui2=AeF7iNno0quai-Rn0A0KPIg8xz5esRj9EUvHPl6xGP0RSwgIC-C8NROz10sp9k8EqIGPmogYa0CfYmCrxfm3NTPP&_nc_ohc=a_pU9I1qwRIAX8pz1Ut&_nc_ht=scontent.fjoi2-1.fna&oh=4331c8f669de9f811fbb3e33164f3e2a&oe=5EB5E17A" slot="end" title="Willyan"></ImageItem>
        <ImageItem img="https://scontent.fjoi2-1.fna.fbcdn.net/v/t1.0-9/46717218_2238497662829699_7046670606808383488_n.jpg?_nc_cat=105&_nc_sid=85a577&_nc_eui2=AeF7iNno0quai-Rn0A0KPIg8xz5esRj9EUvHPl6xGP0RSwgIC-C8NROz10sp9k8EqIGPmogYa0CfYmCrxfm3NTPP&_nc_ohc=a_pU9I1qwRIAX8pz1Ut&_nc_ht=scontent.fjoi2-1.fna&oh=4331c8f669de9f811fbb3e33164f3e2a&oe=5EB5E17A" slot="end" title="Willyan"></ImageItem>

        <Image img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb"></Image>
        
        <ItemDivider title="Exemplo com SGV"></ItemDivider>
        <Styled><ReactLogo  id="x"></ReactLogo></Styled>
        
        </>
    );
}

export const Exemple009 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-thumbnail' de varios tipos
    */
    return (
        <>
            <ItemDivider title="Exemple - 009 (ion-thumbnail)" color="danger"></ItemDivider>

            <ThumbnailItem title="Tutulo" comment="Comentario" img="http://placekitten.com/g/200/300" slot="start"></ThumbnailItem>
            <ThumbnailItem title="Tutulo" comment="Comentario" img="http://placekitten.com/g/200/300" slot="start"></ThumbnailItem>

            <ThumbnailItem title="Tutulo" comment="Comentario" img="https://scontent.fjoi2-1.fna.fbcdn.net/v/t1.0-9/46717218_2238497662829699_7046670606808383488_n.jpg?_nc_cat=105&_nc_sid=85a577&_nc_eui2=AeF7iNno0quai-Rn0A0KPIg8xz5esRj9EUvHPl6xGP0RSwgIC-C8NROz10sp9k8EqIGPmogYa0CfYmCrxfm3NTPP&_nc_ohc=a_pU9I1qwRIAX8pz1Ut&_nc_ht=scontent.fjoi2-1.fna&oh=4331c8f669de9f811fbb3e33164f3e2a&oe=5EB5E17A" slot="end"></ThumbnailItem>
            <ThumbnailItem title="Tutulo" comment="Comentario" img="https://scontent.fjoi2-1.fna.fbcdn.net/v/t1.0-9/46717218_2238497662829699_7046670606808383488_n.jpg?_nc_cat=105&_nc_sid=85a577&_nc_eui2=AeF7iNno0quai-Rn0A0KPIg8xz5esRj9EUvHPl6xGP0RSwgIC-C8NROz10sp9k8EqIGPmogYa0CfYmCrxfm3NTPP&_nc_ohc=a_pU9I1qwRIAX8pz1Ut&_nc_ht=scontent.fjoi2-1.fna&oh=4331c8f669de9f811fbb3e33164f3e2a&oe=5EB5E17A" slot="end"></ThumbnailItem>

            <Thumbnail __background="red" __radius={15} img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb"></Thumbnail>
            <Thumbnail img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb"></Thumbnail>
        </>
    );
}

export const Exemple010 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-thumbnail' de varios tipos
    */
   const [x, setX] = useState<number>(0);
   const [y, setY] = useState<number>(0);
    return (
        <>
            <ItemDivider title="Exemple - 010 (Posicionando elemento)" color="danger"></ItemDivider>

            <IonButton onClick={()=>{setX(x+10)}}><IonIcon slot="end" icon={star}/>+X</IonButton>
            <IonButton onClick={()=>{setX(x-10)}}><IonIcon slot="start" icon={star}/>-X</IonButton>
            <br></br>
            <IonButton onClick={()=>{setY(y+10)}}><IonIcon slot="end" icon={star}/>+Y</IonButton>
            <IonButton onClick={()=>{setY(y-10)}}><IonIcon slot="start" icon={star}/>-Y</IonButton>

            <Thumbnail __posUnit="px" __position="absolute" __x={x} __y={y} img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb"></Thumbnail>
        </>
    );
}


export const Exemple011 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-modal' de varios tipos
    */
   const [showModal, setShowModal] = useState(false);
    return (
        <>
            <ItemDivider title="Exemple - 011 (ion-modal)" color="danger"></ItemDivider>
            <IonModal showBackdrop={true} onDidDismiss={()=>{alert('Closed!')}} isOpen={showModal}>
                <ItemDivider title="Modal aberto" color="primary"></ItemDivider>
                <p>This is modal content</p>
                <IonButton onClick={() => setShowModal(false)}>Close Modal</IonButton>
            </IonModal>
            <IonButton onClick={() => setShowModal(true)}>Show Modal</IonButton>
        </>
    );
}

export const Exemple012 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-popover' de varios tipos
    */
   const [showPopover, setShowPopover] = useState(false);

  return (
    <>
    <ItemDivider title="Exemple - 012 (ion-popover)" color="danger"></ItemDivider>
    
      <IonPopover
        isOpen={showPopover}
        onDidDismiss={e => setShowPopover(false)}
      >
        <IonListHeader lines="inset">
            <IonLabel>Trending</IonLabel>
        </IonListHeader>
        <Item title="Item botão" button={true} ></Item>
        <Item title="Item botão" button={true} detail={true}></Item>
      </IonPopover>
      <IonButton onClick={() => setShowPopover(true)}>Show Popover</IonButton>
    </>
  );
}

export const Exemple013 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-Loading' de varios tipos
    */
   const [showLoading, setShowLoading] = useState(true);

  setTimeout(() => {
    setShowLoading(false);
  }, 3000);

  return (
    <>
    <IonButton onClick={() => setShowLoading(true)}>Show Loading</IonButton>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
        duration={5000}
      />
    </>
  );
}

export const Exemple014 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-Loading' de varios tipos
    */

  return (
    <>
    {/*-- Default Progressbar --*/}
    <IonProgressBar></IonProgressBar><br />

    {/*-- Default Progressbar with 50 percent --*/}
    <IonProgressBar value={0.5}></IonProgressBar><br />

    {/*-- Colorize Progressbar --*/}
    <IonProgressBar color="primary" value={0.5}></IonProgressBar><br />
    <IonProgressBar color="secondary" value={0.5}></IonProgressBar><br />

    {/*-- Other types --*/}
    <IonProgressBar value={0.25} buffer={0.5}></IonProgressBar><br />
    <IonProgressBar type="indeterminate"></IonProgressBar><br />
    <IonProgressBar type="indeterminate" reversed={true}></IonProgressBar><br />
    </>
  );
}

export const Exemple015 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-Loading' de varios tipos
    */

   const Styled = styled.div`
        .custom-skeleton ion-skeleton-text {
            line-height: 15px;
        }
        .custom-skeleton ion-skeleton-text:last-child {
            margin-bottom: 0px;
        }
    `;

   const [data, setData] = useState<any>();

   setTimeout(() => {
     setData({
       heading: 'Normal text',
       para1: 'Lorem ipsum dolor sit amet, consectetur',
       para2: 'adipiscing elit.'
     });
   }, 50000);
   return (
     <>
     {data ? (
         <>
           <div className="ion-padding">
             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac eros est. Cras iaculis pulvinar
             arcu non vehicula. Fusce at quam a eros malesuada condimentum. Aliquam tincidunt tincidunt
             vehicula.
           </div>
 
           <IonList>
             <IonListHeader>
               <IonLabel>
                 Data
               </IonLabel>
             </IonListHeader>
             <IonItem>
               <IonAvatar slot="start">
                 <img src="./avatar.svg" />
               </IonAvatar>
               <IonLabel>
                 <h3>{data.heading}</h3>
                 <p>{data.para1}</p>
                 <p>{data.para2}</p>
               </IonLabel>
             </IonItem>
             <IonItem>
               <IonThumbnail slot="start">
                 <img src="./thumbnail.svg" />
               </IonThumbnail>
               <IonLabel>
                 <h3>{data.heading}</h3>
                 <p>{data.para1}</p>
                 <p>{data.para2}</p>
               </IonLabel>
             </IonItem>
             <IonItem>
               <IonIcon icon={call} slot="start" />
               <IonLabel>
                 <h3>{data.heading}</h3>
                 <p>{data.para1}</p>
                 <p>{data.para2}</p>
               </IonLabel>
             </IonItem>
           </IonList>
         </>
       ) : (
         <Styled>
           <div className="ion-padding custom-skeleton">
             <IonSkeletonText animated style={{ width: '60%' }} />
             <IonSkeletonText animated />
             <IonSkeletonText animated style={{ width: '88%' }} />
             <IonSkeletonText animated style={{ width: '70%' }} />
             <IonSkeletonText animated style={{ width: '60%' }} />
           </div>
 

             <IonListHeader>
               <IonLabel>
                 <IonSkeletonText animated style={{ width: '20%' }} />
               </IonLabel>
             </IonListHeader>
             <IonItem>
               <IonAvatar slot="start">
                 <IonSkeletonText animated />
               </IonAvatar>
               <IonLabel>
                 <h3>
                   <IonSkeletonText animated style={{ width: '50%' }} />
                 </h3>
                 <p>
                   <IonSkeletonText animated style={{ width: '80%' }} />
                 </p>
                 <p>
                   <IonSkeletonText animated style={{ width: '60%' }} />
                 </p>
               </IonLabel>
             </IonItem>
             <IonItem>
               <IonThumbnail slot="start">
                 <IonSkeletonText animated />
               </IonThumbnail>
               <IonLabel>
                 <h3>
                   <IonSkeletonText animated style={{ width: '50%' }} />
                 </h3>
                 <p>
                   <IonSkeletonText animated style={{ width: '80%' }} />
                 </p>
                 <p>
                   <IonSkeletonText animated style={{ width: '60%' }} />
                 </p>
               </IonLabel>
             </IonItem>
             <IonItem>
               <IonSkeletonText animated style={{ width: '27px', height: '27px' }} slot="start" />
               <IonLabel>
                 <h3>
                   <IonSkeletonText animated style={{ width: '50%' }} />
                 </h3>
                 <p>
                   <IonSkeletonText animated style={{ width: '80%' }} />
                 </p>
                 <p>
                   <IonSkeletonText animated style={{ width: '60%' }} />
                 </p>
               </IonLabel>
             </IonItem>

         </Styled>
       )}
     </>
   );
}

export const Exemple016 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-alert' de varios tipos
    */
   const [showAlert1, setShowAlert1] = useState(false);
   const [showAlert2, setShowAlert2] = useState(false);
   const [showAlert3, setShowAlert3] = useState(false);
   const [showAlert4, setShowAlert4] = useState(false);
   const [showAlert5, setShowAlert5] = useState(false);
   return (
     <>
        <ItemDivider title="Exemple - 016 (ion-alert)" color="danger"></ItemDivider>

        <IonButton onClick={() => setShowAlert1(true)} expand="block">Show Alert-Ok</IonButton>
        <IonButton onClick={() => setShowAlert2(true)} expand="block">Show Alert-Ok-Cancel</IonButton>
        <IonButton onClick={() => setShowAlert3(true)} expand="block">Show Loading</IonButton>
        <IonButton onClick={() => setShowAlert4(true)} expand="block">Show Toast</IonButton>
        <IonButton onClick={() => setShowAlert5(true)} expand="block">Show Toast with buttons</IonButton>


        <AlertOk backdropDismiss={false} onDidDismiss={() => setShowAlert1(false)} isOpen={showAlert1} title="Alert Simples" message="Menssagem simples com ok"></AlertOk>
        <AlertOkCancel backdropDismiss={true} onOk={()=>{alert('OK!')}} onDidDismiss={() => setShowAlert2(false)} isOpen={showAlert2} title="Alert ok e cancel" message="Menssagem simples com ok e cancelar"></AlertOkCancel>
        <Loading duration={5000} message="Agurade.." isOpen={showAlert3} onDidDismiss={() => setShowAlert3(false)}></Loading>
        <Toast duration={1000} onDidDismiss={() => setShowAlert4(false)} isOpen={showAlert4} message="Nova msg chegou.."></Toast>

        <Toast position="top" onDidDismiss={() => setShowAlert5(false)} buttons={[
            {
                side: 'end',
                icon: star,
                text: 'Favorite',
                handler: () => {
                  console.log('Favorite clicked');
                }
              },
              {
                side: 'end',
                icon: star,
                text: 'Done',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
        ]} isOpen={showAlert5} message="Nova msg chegou.."></Toast>

     </>
   );
}

export const Exemple017 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-button/toggle' de varios tipos
    */
   const [checked1, setChecked1] = useState<boolean>(false);
   const [checked2, setChecked2] = useState<boolean>(false);
   const [checked3, setChecked3] = useState<boolean>(false);
   const [checked4, setChecked4] = useState<boolean>(false);
   return (
     <>
        <ItemDivider title="Exemple - 017 (ToggleItem)" color="danger"></ItemDivider>

        <ItemDivider title="Saboros Pizza"></ItemDivider>
        <ToggleItem checked={checked1} onChange={(val)=>{setChecked1(val);alert(val)}} color="primary" label="Queijo"></ToggleItem>
        <ToggleItem checked={checked2} onChange={(val)=>{setChecked2(val);alert(val)}} color="danger" label="Salame"></ToggleItem>

        <ItemDivider title="Toggle sem label"></ItemDivider>
        <ToggleItem checked={checked3} onChange={(val)=>{setChecked3(val);alert(val)}} ></ToggleItem>
        <ToggleItem checked={checked4} onChange={(val)=>{setChecked4(val);alert(val)}} ></ToggleItem>
     </>
   );
}

export const Exemple018 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-GRID' de varios tipos
    */
   const Styled = styled.div`
    #xx {
        width:50%;
        position:relative;

    };
    #yy {
        width:50%;
        position:relative;
        
    };
    `;
    const img1: string = 'http://placekitten.com/g/200/300';
    const img2: string = 'https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb';
    interface IProps_Img {
        img: string;
        title: string
      }
    const Img = ({img,title,...props}:IProps_Img):JSX.Element => (
        <ImageItem img={img}slot="start"title={title}></ImageItem>
    )
    return (
        <>
        <ItemDivider title="Exemple - 018 (Varios tipos de grid)" color="danger"></ItemDivider>

        <ItemDivider title="Grid de listas, para varios tamanhos de tela"></ItemDivider>
        <IonGrid>
        <IonRow class="ion-justify-content-center">
            <IonCol sizeLg="3"sizeMd="4"><Img img={img1} title='Gato'></Img></IonCol>
            <IonCol sizeLg="3"sizeMd="4"><Img img={img2} title='Willyan'></Img></IonCol>
            
            <IonCol sizeLg="3"sizeMd="4"><Img img={img1} title='Gato'></Img></IonCol>
            <IonCol sizeLg="3"sizeMd="4"><Img img={img2} title='Willyan'></Img></IonCol>

            <IonCol sizeLg="3"sizeMd="4"><Img img={img1} title='Gato'></Img></IonCol>
            <IonCol sizeLg="3"sizeMd="4"><Img img={img2} title='Willyan'></Img></IonCol>

            <IonCol sizeLg="3"sizeMd="4"><Img img={img1} title='Gato'></Img></IonCol>
            <IonCol sizeLg="3"sizeMd="4"><Img img={img2} title='Willyan'></Img></IonCol>

            <IonCol sizeLg="3"sizeMd="4"><Img img={img1} title='Gato'></Img></IonCol>
            <IonCol sizeLg="3"sizeMd="4"><Img img={img2} title='Willyan'></Img></IonCol>
        </IonRow>
        
        <ItemDivider title="Grid de item unico, como player de video e afins.."></ItemDivider>
        <IonRow class="ion-justify-content-center">
        {/* <IonCol sizeSm="1"sizeLg="3"sizeMd="4"><Image __size={true} __width={60} __height={40} img={img1}></Image></IonCol> */}
            <IonCol sizeSm="1"sizeLg="3"sizeMd="4"><Image img={img1}></Image></IonCol>
        </IonRow>
        
        </IonGrid>

        <IonGrid>
        <IonRow>
            <IonCol>
                <ImageItem img={img1}slot="start"title="Cat"></ImageItem>
            </IonCol>
            <IonCol>
                <ImageItem img={img1}slot="start"title="Cat"></ImageItem>
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol size="6"><ImageItem img={img2}slot="start"title="Cat"></ImageItem></IonCol>
            <IonCol><ImageItem img={img2}slot="start"title="Cat"></ImageItem></IonCol>
            <IonCol><ImageItem img={img2}slot="start"title="Cat"></ImageItem></IonCol>
        </IonRow>

        <IonRow>
            <IonCol size="3">ion-col size="3"</IonCol>
            <IonCol>ion-col</IonCol>
            <IonCol size="3">ion-col size="3"</IonCol>
        </IonRow>

        <IonRow>
            <IonCol size="3">ion-col size="3"</IonCol>
            <IonCol size="3" offset="3">
            ion-col size="3" offset="3"
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol>ion-col</IonCol>
            <IonCol>
            ion-col
            <br />#
            </IonCol>
            <IonCol>
            ion-col
            <br />#
            <br />#
            </IonCol>
            <IonCol>
            ion-col
            <br />#
            <br />#
            <br />#
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol className="ion-align-self-start">ion-col start</IonCol>
            <IonCol className="ion-align-self-center">ion-col center</IonCol>
            <IonCol className="ion-align-self-end">ion-col end</IonCol>
            <IonCol>
            ion-col
            <br />#
            <br />#
            </IonCol>
        </IonRow>

        <IonRow className="ion-align-items-start">
            <IonCol>start ion-col</IonCol>
            <IonCol>start ion-col</IonCol>
            <IonCol className="ion-align-self-end">start ion-col end</IonCol>
            <IonCol>
            ion-col
            <br />#
            <br />#
            </IonCol>
        </IonRow>

        <IonRow className="ion-align-items-center">
            <IonCol>center ion-col</IonCol>
            <IonCol>center ion-col</IonCol>
            <IonCol>center ion-col</IonCol>
            <IonCol>
            ion-col
            <br />#
            <br />#
            </IonCol>
        </IonRow>

        <IonRow className="ion-align-items-end">
            <IonCol>end ion-col</IonCol>
            <IonCol className="ion-align-self-start">end ion-col start</IonCol>
            <IonCol>end ion-col</IonCol>
            <IonCol>
            ion-col
            <br />#
            <br />#
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol size="12" size-sm>
            ion-col size="12" size-sm
            </IonCol>
            <IonCol size="12" size-sm>
            ion-col size="12" size-sm
            </IonCol>
            <IonCol size="12" size-sm>
            ion-col size="12" size-sm
            </IonCol>
            <IonCol size="12" size-sm>
            ion-col size="12" size-sm
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol size="12" size-md>
            ion-col size="12" size-md
            </IonCol>
            <IonCol size="12" size-md>
            ion-col size="12" size-md
            </IonCol>
            <IonCol size="12" size-md>
            ion-col size="12" size-md
            </IonCol>
            <IonCol size="12" size-md>
            ion-col size="12" size-md
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol size="6" size-lg offset="3">
            ion-col size="6" size-lg offset="3"
            </IonCol>
            <IonCol size="3" size-lg>
            ion-col size="3" size-lg
            </IonCol>
        </IonRow>
        </IonGrid>
        <div id="x">
            <ImageItem img="http://placekitten.com/g/200/300" slot="start" title="Cat"></ImageItem>
            <ImageItem img="http://placekitten.com/g/200/300" slot="start" title="Cat"></ImageItem>
            <ImageItem img="http://placekitten.com/g/200/300" slot="start" title="Cat"></ImageItem>
            <ImageItem img="http://placekitten.com/g/200/300" slot="start" title="Cat"></ImageItem>
        </div>

        <div id="y">
            <ImageItem img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb" slot="start" title="Willyan"></ImageItem>
            <ImageItem img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb" slot="start" title="Willyan"></ImageItem>
            <ImageItem img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb" slot="start" title="Willyan"></ImageItem>
            <ImageItem img="https://secure.gravatar.com/avatar/e345dd4dd3f3a7487db5714104e97cdb" slot="start" title="Willyan"></ImageItem>
        </div>

        <ImageItem img="https://scontent.fjoi2-1.fna.fbcdn.net/v/t1.0-9/46717218_2238497662829699_7046670606808383488_n.jpg?_nc_cat=105&_nc_sid=85a577&_nc_eui2=AeF7iNno0quai-Rn0A0KPIg8xz5esRj9EUvHPl6xGP0RSwgIC-C8NROz10sp9k8EqIGPmogYa0CfYmCrxfm3NTPP&_nc_ohc=a_pU9I1qwRIAX8pz1Ut&_nc_ht=scontent.fjoi2-1.fna&oh=4331c8f669de9f811fbb3e33164f3e2a&oe=5EB5E17A" slot="end" title="Willyan"></ImageItem>
        <ImageItem img="https://scontent.fjoi2-1.fna.fbcdn.net/v/t1.0-9/46717218_2238497662829699_7046670606808383488_n.jpg?_nc_cat=105&_nc_sid=85a577&_nc_eui2=AeF7iNno0quai-Rn0A0KPIg8xz5esRj9EUvHPl6xGP0RSwgIC-C8NROz10sp9k8EqIGPmogYa0CfYmCrxfm3NTPP&_nc_ohc=a_pU9I1qwRIAX8pz1Ut&_nc_ht=scontent.fjoi2-1.fna&oh=4331c8f669de9f811fbb3e33164f3e2a&oe=5EB5E17A" slot="end" title="Willyan"></ImageItem>
        
        </>
    );
}

export const Exemple019 : React.FC = () => {
    /* 
    Exemplo:
    Exemplo com 'ion-GRID' de varios tipos
    */

    //@ts-ignore
   const { state, dispatch } = useContext(AppContext);

    return (
        <>
        <ItemDivider title="Exemple - 019 (Storange com AppContext e diretamente com local storage)" color="danger"></ItemDivider>

        <ItemDivider title="Disparando dispatch para atualizar o state global:"></ItemDivider>
        <IonButton onClick={()=>{
            
            dispatch({
                type: 'xxx',
                payload: 'abc'
            });
            console.log('state:',state);
        }}>Change State</IonButton>

        <ItemDivider title="Alterando local sorange diretamente, sem uso de componente react:"></ItemDivider>
        <IonButton onClick={()=>{
            
            Storage.write({db:Storage.read().db,preferences:{...Storage.read().preferences},boardList:{...Storage.read().boardList},session:{...Storage.read().session,x:'abc'}})
            console.log('Storage:',Storage.read());
            console.log('state:',state);
        }}>Change State</IonButton>

        <ItemDivider title="Atualiza o state e notifica o react, caso tenha alterado o storage sem uso de componente react:"></ItemDivider>
        <IonButton onClick={()=>{
            
            dispatch({
                type: 'refresh',
                payload:null
            });
            console.log('state refreshed:',state);
        }}>Force Read State</IonButton>

        </>
    );
}

export const Exemple020 : React.FC = () => {
  /* 
  Exemplo:
  Exemplo com 'global state e exemplo de socket-io'
  */

  //@ts-ignore
 const { state, dispatch } = useContext(AppContext);

  return (
      <>
      <ItemDivider title="Exemple - 020 (alterando global state e exemplo de socket-io)" color="danger"></ItemDivider>

      <IonButton onClick={()=>{
          LocalDBStorage.setRemote();
          //LocalDBStorage.setLocal();
          dispatch({type: 'refresh',payload:null})
      }}>Change parameter and save it</IonButton>

      <IonButton onClick={async()=>{
          if(await socket.write('message','Local or Remote React MSG!','...more payload here..')){console.log('ok')}else{console.log('nok')}
      }}>Write simple MSG</IonButton>
      
      <IonButton onClick={async()=>{
          try {
            let resp = await socket.waitFor('GetLowDBUser_Local','GetLowDBUser_Local','Write a msg and wait feddback','...more payload here...');
            console.log('Wait ok. Data:',...resp)
          } catch (error) {
            console.error('Wait nok. Error:',error)
          }
      }}>Write MSG and wait for response</IonButton>

      <IonButton onClick={async()=>{
          try {
            let resp = await socket.wait('GetLowDBUser_Local','Write a msg and wait feddback','...more payload here...');
            console.log('Wait ok. Data:',...resp)
          } catch (error) {
            console.error('Wait nok. Error:',error)
          }
      }}>Write MSG and wait response(the same wrote)</IonButton>

      </>
  );
}