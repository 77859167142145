import styled from 'styled-components';

export const Styled_ItemDivider = styled.div`
    ion-picker {
        --background:var(--ion-color-danger, red);
    }
`;
// export const Styled_ItemDivider = styled.div`
//     ion-item-divider {
//         color: var(--ion-color-primary, red);; 
//     };
// `;



















// //https://www.youtube.com/watch?v=R3S8DEzEn6s
// export const StyledComponents = styled.div`
//     font-size: ${props => `${props.fontSize}px`};
//     font-size: ${(props) => `${alert(`props : ${props}`)}`};
//     color: blue; 
//     span {
//         font-size: 12px;
//     };
//     ion-item-divider {
//         color: blue; 
//     };
// `;