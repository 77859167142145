
import * as model from '../model';

import {socket,Storage} from '../../../resources/app/resource.instance'
import { list } from 'ionicons/icons';

export class LocalDBStorage{
    public static LocalDB():model.db_local{
        return Storage.read().db.local;
    }

    public static LocalBoardName():string{
        return Storage.read().db.BoardName;
    }
    
    public static LocalBoardNameList():Array<string>{
        let elem = Storage.read();
        let list = elem.boardList.map((val,index,arr)=>{
            return val.BoardName
        });
        return list;
    }

    public static ChangeBoard(newIndex:number){
        //Antes de alterar salvamos a ulima vez os dados da placa atual:
        let boardList = Storage.read().boardList;
        boardList[Storage.read().preferences.BoardIndex] = Storage.read().db
        Storage.write({
            boardList:boardList,
            db:Storage.read().db,
            preferences:Storage.read().preferences,
            session:Storage.read().session,
        })
        //Agora alteramos de ambiente/placa:
        Storage.write({
            boardList:Storage.read().boardList,
            db:Storage.read().boardList[newIndex],
            preferences:{...Storage.read().preferences,BoardIndex:newIndex},
            session:Storage.read().session,
        })
    }

    public static Preferences(){
        return Storage.read().preferences;
    }

    public static setLocal(){
        socket.setLocal();
        Storage.write({db:{...Storage.read().db,local:{...Storage.read().db.local,isLocal:true}},preferences:{...Storage.read().preferences},boardList:[...Storage.read().boardList],session:{...Storage.read().session}})
    }
    public static setRemote(){
        socket.setRemote();
        Storage.write({db:{...Storage.read().db,local:{...Storage.read().db.local,isLocal:false}},preferences:{...Storage.read().preferences},boardList:[...Storage.read().boardList],session:{...Storage.read().session}})
    }

    public static isLocal(){
        return Storage.read().db.local.isLocal? true : false;
    }

    public static isRemote(){
        return Storage.read().db.local.isLocal? false : true;
    }

    public static setLocalIP(ip: string){
        Storage.write({db:{...Storage.read().db,local:{...Storage.read().db.local,network:{...Storage.read().db.local.network,local_ip:ip}}},preferences:{...Storage.read().preferences},boardList:[...Storage.read().boardList],session:{...Storage.read().session}});
        this.socket_config();

    }
    public static setLocalPort(port: number){
        Storage.write({db:{...Storage.read().db,local:{...Storage.read().db.local,network:{...Storage.read().db.local.network,local_port:port}}},preferences:{...Storage.read().preferences},boardList:[...Storage.read().boardList],session:{...Storage.read().session}})
        this.socket_config();
    }
    public static setRemoteIP(ip: string){
        Storage.write({db:{...Storage.read().db,local:{...Storage.read().db.local,network:{...Storage.read().db.local.network,remote_ip:ip}}},preferences:{...Storage.read().preferences},boardList:[...Storage.read().boardList],session:{...Storage.read().session}});
        this.socket_config();

    }
    public static setRemotePort(port: number){
        Storage.write({db:{...Storage.read().db,local:{...Storage.read().db.local,network:{...Storage.read().db.local.network,remote_port:port}}},preferences:{...Storage.read().preferences},boardList:[...Storage.read().boardList],session:{...Storage.read().session}})
        this.socket_config();
    }

    public static setUser(email: string){
        Storage.write({db:{...Storage.read().db,local:{...Storage.read().db.local,user:{...Storage.read().db.local.user,email:email}}},preferences:{...Storage.read().preferences},boardList:[...Storage.read().boardList],session:{...Storage.read().session}});
        this.socket_config();
    }
    public static setUserPwd(pwd: string){
        Storage.write({db:{...Storage.read().db,local:{...Storage.read().db.local,user:{...Storage.read().db.local.user,pwd:pwd}}},preferences:{...Storage.read().preferences},boardList:[...Storage.read().boardList],session:{...Storage.read().session}});
        this.socket_config();
    }

    //--- Preferencias ---
    public static setHomeName(name: string){
        Storage.write({db:{...Storage.read().db,BoardName:name,local:{...Storage.read().db.local}},preferences:{...Storage.read().preferences},boardList:[...Storage.read().boardList],session:{...Storage.read().session}});
        this.socket_config();
    }

    public static socket_config(){
        socket.config(
            //Storage.read().db.local.isLocal,
            Storage.read().db.local.network.local_ip,
            Storage.read().db.local.network.local_port,
            Storage.read().db.local.network.remote_ip,
            Storage.read().db.local.network.remote_port,
            Storage.read().db.local.remote_roomName_hashSecret,//Read Only
            Storage.read().db.local.user.email,
            Storage.read().db.local.user.pwd || '',
        )
    }
}