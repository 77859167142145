import moment from 'moment'
var dateFormat = require('dateformat');

//https://stackoverflow.com/questions/951021/what-is-the-javascript-version-of-sleep
  export const sleep = (time_ms:number) => {
    return new Promise((resolve, reject) => {
      setTimeout(()=>{resolve()}, time_ms)
    });
};

export interface TimeInterval_Model{
  name: string, //Exemplo: 'Dia todo'
  start: string,
  end: string,
  format: string, //TimeFormatTypes_Model
}
export enum TimeFormatTypes_Model{
  hh_mm_ss = 'hh:mm:ss',
  hh_mm = 'hh:mm',
  yyyy_MM_dd_hh_mm_ss = 'yyyy-MM-dd hh:mm:ss',
  yyyy_MM_dd_hh_mm = 'yyyy-MM-dd hh:mm',
  wd = 'wd', //WeekDay. Ex: '06' -> Saturday(JS format) / Friday(MySQL format)
}
// export interface Time_Model{
//   Hours:number,Minutes:number,Seconds:number//,Year:number,Month:number, Day:number, weekDay: number
// }
// export interface DateTime_Model{
//   Time:Time_Model,Year:number,Month:number, Day:number, weekDay: number
// }
export enum JavaScriptWeekDay_Model{
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}
export enum MySQLWeekDay_Model{
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
}

export function InRange(min:number,max:number,test:number):boolean{
  if (test >= min && test <= max) {
    return true;
  } else {
    return false;
  }
}
//Check a time string(in hh:mm format. Ex: 22:30):
export function InRange_hh_mm_ss(min:string,max:string,test:Date):boolean{
  let test_formated = dateFormat(test, "HH:MM:ss")

  return moment(`2000-01-01 ${test_formated}`).isBetween(`2000-01-01 ${min}`, `2000-01-01 ${max}`);
}
export function InRange_hh_mm(min:string,max:string,test:Date):boolean{
  let test_formated = dateFormat(test, "HH:MM")

  return moment(`2000-01-01 ${test_formated}:00`).isBetween(`2000-01-01 ${min}:00`, `2000-01-01 ${max}:00`);
}
export function InRange_yyyy_MM_dd_hh_mm_ss(min:string,max:string,test:Date):boolean{
  let test_formated = dateFormat(test, "yyyy-mm-dd HH:MM:ss")

  return moment(`${test_formated}`).isBetween(`${min}`, `${max}`);
}
export function InRange_yyyy_MM_dd_hh_mm(min:string,max:string,test:Date):boolean{
  let test_formated = dateFormat(test, "yyyy-mm-dd HH:MM")

  return moment(`${test_formated}:00`).isBetween(`${min}:00`, `${max}:00`);
}
export function InRange_wd(min:string,max:string,test:Date):boolean{
  let test_formated: number = test.getDay();
  return InRange(Number(min),Number(max),Number(test_formated));
}
export function InRange_DateTime_AnyAtList(list:Array<{min:string,max:string}>,test:Date, format:string):boolean{
  let result_ok: boolean = false;
  for (let index = 0; index < list.length; index++) {
    const element = list[index];
    result_ok = InRange_DateTime(element,test,format);
    if(result_ok){return true;}
  }
  return false;
}
export function InRange_DateTime(element:{min:string,max:string},test:Date, format:string):boolean{
  switch (format) {
    case TimeFormatTypes_Model.hh_mm_ss:
      return InRange_hh_mm_ss(element.min,element.max,test)
      break;
    case TimeFormatTypes_Model.hh_mm:
      return InRange_hh_mm(element.min,element.max,test)
      break;
    case TimeFormatTypes_Model.yyyy_MM_dd_hh_mm_ss:
      return InRange_yyyy_MM_dd_hh_mm_ss(element.min,element.max,test)
      break;
    case TimeFormatTypes_Model.yyyy_MM_dd_hh_mm:
      return InRange_yyyy_MM_dd_hh_mm(element.min,element.max,test)
      break;
    case TimeFormatTypes_Model.wd:
      return InRange_wd(element.min,element.max,test)
      break;
    default:
      return false;
      break;
  }
}
export async function WaitForDateTimeBeInRange_AnyAtList(list:Array<{min:string,max:string}>,testFunc:() => Date, format:string,limitToWaitFor_time?:number){
  return new Promise((resolve,reject)=>{
    if (limitToWaitFor_time) {setTimeout(() => {reject();}, limitToWaitFor_time);}

    let timeout = setInterval(() => {
        let test: Date = testFunc()
        let result_ok: boolean = false;
        result_ok = InRange_DateTime_AnyAtList(list,test,format)
        if (result_ok) {
            resolve();clearTimeout(timeout);
        }
    }, 500);
})
}

export async function WaitForBeTrue(testFunc:() => boolean, clock?:number,limitToWaitFor_time?:number){
  return new Promise((resolve,reject)=>{
    if (limitToWaitFor_time) {setTimeout(() => {
      reject();
      clearTimeout(timeout);
    }, limitToWaitFor_time);}

    let timeout = setInterval(() => {
        let test: boolean = testFunc();
        if (test) {
            resolve();clearTimeout(timeout);
        }
    }, clock || 500);
})
}

export async function WaitForBeFalse(testFunc:() => boolean, limitToWaitFor_time?:number){
  return new Promise((resolve,reject)=>{
    if (limitToWaitFor_time) {setTimeout(() => {reject();}, limitToWaitFor_time);}

    let timeout = setInterval(() => {
        let test: boolean = testFunc();
        if (test === false) {
            resolve();clearTimeout(timeout);
        }
    }, 500);
})
}

function test(){
  //let result = moment('2000-01-01 22:00:00').isBetween('2000-01-01 21:00:00', '2000-01-01 23:00:00');
  //let result = moment('2010-10-20').isBetween('2010-10-19', '2010-10-25');
  //let result = InRange_hh_mm('18:00','19:00','18:45')
  //let now = moment();

  let now = new Date();
  let now_formated = dateFormat(now, "yyyy-mm-dd HH:MM:ss")
  console.log(now)
}
test();