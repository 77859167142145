import {Storage} from './resource.instance'
import {UserDBStorage} from '../../components/storage/CRUD/storage.user.list.db'
import {LocalDBStorage} from '../../components/storage/CRUD/storage.local.db'
var dateFormat = require('dateformat');

async function FirstLoadTasks(){
    try {
        Storage.write({...Storage.read(),session:{OnLoadedAtTimestamp:dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss")}});
        await UserDBStorage.GetUsers(true);
        LocalDBStorage.socket_config();
    } catch (error) {
        
    }
}

export async function FirstLoad(callback:()=>void){
    setTimeout(async() => {
        await FirstLoadTasks();
        callback();
    }, 1000);
}