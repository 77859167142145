//Se voce quer utilizar o storage como um componente react, então este componente é
//para voce!

import React, { createContext, useEffect, useReducer, useCallback, useState } from 'react';
import { useLocalStorage } from '../localstorage/useLocalStorage';
import * as model from './model';
import {Storage} from './storage'
import * as env from './../../resources/data/env'
import {FirstLoad} from '../../resources/app/first-load'
import {instance_initialize} from '../../resources/app/resource.instance';
import {Toast,Loading} from '../../components/ionic/alert/alert';
var dateFormat = require('dateformat');

enum AppContextActionTypesEnum{
  OnNetworkError='OnNetworkError'
}

export const AppContext = createContext<{state:model.dataModel|undefined,dispatch:(data:{type:string,payload:any})=>void}>({state:undefined,dispatch:()=>{}});
//export const AppContext = createContext(null); //Uso do 'AppContext' de forma mais simples, sem determinar tipo.

const initialState: model.dataModel = model.model.data_get_default();

const reducer = (state: model.dataModel, action: {type:string,payload:any}): model.dataModel => {
  if (action.type === 'refresh') {
    console.log('Env:',env,process.env)
    return Storage.read();
  }
  if (action.type === AppContextActionTypesEnum.OnNetworkError) {
    return { ...state, session: { ...state.session, OnNetworkErrorTimestamp:dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss") } }
  }
  if (action.type === 'xxx') {
    //return { ...state, session: { ...state.session, www123: action.payload } }
  }
  if (action.type === 'abc') {
    //return { ...state, session: { ...state.session, abc: action.payload } }
  }
  return state;
}

export const AppContextProvider = (props: any) => {

  //Feddback geral do socketIO:
  const [toast, setToast] = useState({show:false,msg:''});
  const [loading, setLoading] = useState({show:false,msg:''});

  //Inicializa as instancias so no carregamento inicial:
  useEffect(()=>{
    instance_initialize(()=>{
      dispatch({type: 'refresh',payload:null})
    },
    ()=>{/* OnResponseWaiting */ setLoading({show:true,msg:`Aguarde..`});},
    ()=>{/* OnResponseArrived */ setLoading({show:false,msg:``});},
    ()=>{/* OnResponseError */   setLoading({show:false,msg:``});setToast({show:true,msg:`Problemas na conexão. Os dados mostrados podem estar diferente...`});dispatch({type: AppContextActionTypesEnum.OnNetworkError,payload:null});},
    ()=>{/* OnUserCredentialsError */ setLoading({show:false,msg:``});setToast({show:true,msg:`Nome ou senha do usuário invalidos.`});},
    ()=>{/* OnUserWithoutRightsError */ setLoading({show:false,msg:``});setToast({show:true,msg:`Voce não é administrador e portanto não pode executar essa ação`});},
    );
  },[])
  
  //Local storage no estilo 'useState':
  Storage.setKeyName('data_xxx31')
  const [data, setData] = useLocalStorage(Storage.getKeyName(), initialState); //useLocalStorage('xxx', initialState);

  //Estado global 'state' com redux:
  let [state, dispatch] = useReducer(reducer, data);
  let value = { state, dispatch };
  
  //Esse 'useEffect' foi dicionado para carregar dados do backend no primeiro carregamento:
  useEffect(() => {
    console.log('loaded first data: ', state)
    FirstLoad(async()=>{dispatch({type:'refresh',payload:null});console.warn("Loaded app ok!")})
    setData(state);
  }, []);

  //Se o state mudar(state -> global redux memory data), então salvamos no local storage(setData):
  useEffect(() => {
    console.log('loaded data when it changes',state)
    setData(state);
  }, [state, /* setData */]);


  return (
    // @ts-ignore
    <AppContext.Provider value={value}>{props.children}
    <Toast duration={3000} onDidDismiss={() => setToast({show:false,msg:''})} isOpen={toast.show} message={toast.msg}></Toast>
    <Loading isOpen={loading.show} message={loading.msg}></Loading>
    </AppContext.Provider>
  );
}