
import * as model from '../model';
import {socket,Storage} from '../../../resources/app/resource.instance';

export enum SocketIORequestType_LocationList{
    GetLowDB_LocationList='GetLowDB_LocationList',
    SetLowDB_LocationList='SetLowDB_LocationList',
}

export class LocationsDBStorage{
    public static async GetLocationList(online?:boolean):Promise<model.db_locations>{
        try {
            if (online) {
                let actual = Storage.read();
                let [data] = await socket.waitFor(SocketIORequestType_LocationList.GetLowDB_LocationList,SocketIORequestType_LocationList.GetLowDB_LocationList,null)
                Storage.write({...actual,db:{...actual.db,locations:{List:data}}});
                actual = Storage.read();
                return Storage.read().db.locations;
            } else {
                return Storage.read().db.locations;
            }
        } catch (error) {
            throw 'Error at "storage.locations.db->LocalDBStorage.LocalDB" on get data.'
        }
    }
    public static async SetLocationList(online:boolean,localList:model.db_locations):Promise<model.db_locations>{
        try {
            if (online) {
                let [data] = await socket.waitFor(SocketIORequestType_LocationList.SetLowDB_LocationList,SocketIORequestType_LocationList.SetLowDB_LocationList,localList.List)
                Storage.write({...Storage.read(),db:{...Storage.read().db,locations:{List:data}}})
                return Storage.read().db.locations;
            } else {
                Storage.write({...Storage.read(),db:{...Storage.read().db,locations:{List:localList.List}}});
                return Storage.read().db.locations;
            }
        } catch (error) {
            throw 'Error at "storage.locations.db->LocalDBStorage.SetLocalDB" on get data.'
        }
    }
}