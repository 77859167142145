function start(_data_path:string, _env_generic_file: string, _env_test_file: string, _env_prod_file: string){
    //@ts-ignorets
    const { resolve } = require('path')
    
    //Global/Generic .env file:
    require('dotenv').config({
        override: true,
        path: _data_path + _env_generic_file
    })

    const production: boolean = (process.env.___PRODUCTION_MODE === 'true');

    let env_path = _data_path + (production ? _env_prod_file : _env_test_file)

    //Production/test .env file:
    require('dotenv').config({
        override: true,
        path: env_path
    })
}

// ----- Check OS variables and turn it global -----
//REMENBER: THIS VAR MUSB BE CONFIGURED ON OS(LINUX OR WINDOWS): HS_PATH=/home/pi/hs
//const ___ROOT_PATH:string = process.env.HS_PATH ? process.env.HS_PATH + '/' : '/home/pi/hs/'; //--->    /home/pi/hs    C:/Projetos/nodejs
//const ___ROOT_FOLDER:string = 'home-sense-nodejs';
//export const ___PATH:string = ___ROOT_PATH + ___ROOT_FOLDER + '/';
//export const ___DATA_PATH:string = ___PATH + 'data' + '/';
// ----- Check OS variables and turn it global -----

// ----- Turn .env file variables global -----
start('/','.env_','.env.development','.env.production'); //This dont work with ionic/react!
//require('dotenv').config()

//From Global/Generic .env file:
export const REACT_APP_PRODUCTION_MODE:boolean = (process.env.REACT_APP_PRODUCTION_MODE === 'true');

//From Production/test .env file:
export const REACT_APP_REMOTE_SERVER_IP:string | undefined = process.env.REACT_APP_REMOTE_SERVER_IP
export const REACT_APP_REMOTE_SERVER_PORT:number | undefined = Number(process.env.REACT_APP_REMOTE_SERVER_PORT)

export const REACT_APP_LOCAL_SERVER_PORT:number | undefined = Number(process.env.REACT_APP_LOCAL_SERVER_PORT)
// ----- Turn .env file variables global -----