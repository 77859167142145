
import * as model from '../model';
import {socket,Storage} from '../../../resources/app/resource.instance';

export enum SocketIORequestType_User{
    GetLowDBUser_Local='GetLowDBUser_Local',
    SetLowDBUser_Local='SetLowDBUser_Local',
}

export class UserDBStorage{
    public static async GetUsers(online?:boolean):Promise<model.db_users>{
        try {
            if (online) {
                let actual = Storage.read();
                let [data] = await socket.waitFor(SocketIORequestType_User.GetLowDBUser_Local,SocketIORequestType_User.GetLowDBUser_Local,null)
                Storage.write({...actual,db:{...actual.db,user:{List:data}}});
                actual = Storage.read();
                return Storage.read().db.user;
            } else {
                return Storage.read().db.user;
            }
        } catch (error) {
            throw 'Error at "storage.user.db->UserDBStorage.UsersDB" on get data.'
        }
    }
    public static async SetUsers(online:boolean,userList:model.db_users):Promise<model.db_users>{
        try {
            if (online) {
                let [data] = await socket.waitFor(SocketIORequestType_User.SetLowDBUser_Local,SocketIORequestType_User.SetLowDBUser_Local,userList.List)
                Storage.write({...Storage.read(),db:{...Storage.read().db,user:{List:data}}})
                return Storage.read().db.user;
            } else {
                Storage.write({...Storage.read(),db:{...Storage.read().db,user:{List:userList.List}}});
                return Storage.read().db.user;
            }
        } catch (error) {
            throw 'Error at "storage.user.db->UserDBStorage.SetUsersDB" on get data.'
        }
    }
}