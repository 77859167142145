import { Api } from './api'
import { SocketIO,ResponseRefuseReasonEnum } from "./socket.io";
import {SocketIORequestType_User} from '../../components/storage/CRUD/storage.user.list.db';
import {SocketIORequestType_LocationList,LocationsDBStorage} from '../../components/storage/CRUD/storage.location.list.db';
import {SocketIORequestType_Hardware,HardwareDBStorage} from '../../components/storage/CRUD/storage.hardware.db';
import * as model from '../../components/storage/model'

import {Storage as _Storage} from '../../components/storage/storage';
import * as env from '../data/env'

import {UserDBStorage} from '../../components/storage/CRUD/storage.user.list.db'

var dateFormat = require('dateformat');

export let api: Api
export let socket: SocketIO
export let Storage = _Storage; //Classe estatica

function initialize_Storage(){
    //Storage.setKeyName('data_xxx8');
    Storage.clearSession();
}

function initialize_api(): Api {
    let elem: Api = new Api()
    return elem;
}

function initialize_SocketIO(
    OnRemoteChanges:()=>void,
    //Callbacks gerais do socket-io:
    OnResponseWaiting:()=>void,
    OnResponseArrived:()=>void,
    OnResponseError:()=>void,
    OnUserCredentialsError:()=>void,
    OnUserWithoutRightsError:()=>void,
    
    ): SocketIO {
    let data = Storage.read();
    let elem: SocketIO = new SocketIO(
        (ResponseRefuseReason)=>{/* OnResponseRefusedError */},
        (head,payload)=>{ /* OnExternalMessagesReceived */
            /* Callback para msg recebidas de outros usuarios/sockets/devices */
            if(head.responseHeader?.inResponseToUser){
                if(head.contentType == SocketIORequestType_User.SetLowDBUser_Local){
                    //alert('SetLowDBUser_Local from other user!');
                    let _payload:model.db_users = {List:payload[0]}
                    UserDBStorage.SetUsers(false,_payload)
    
                    Storage.write({...Storage.read(),session:{OnRemoteChangesTimestamp:dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss")}})
                }
                if(head.contentType == SocketIORequestType_LocationList.SetLowDB_LocationList){
                    //alert('SetLowDBUser_Local from other user!');
                    let _payload:model.db_locations = {List:payload[0]}
                    LocationsDBStorage.SetLocationList(false,_payload)
    
                    Storage.write({...Storage.read(),session:{OnRemoteChangesTimestamp:dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss")}})
                }
                if(head.contentType == SocketIORequestType_Hardware.SetLowDB_Hardware_OutputI2cModules){
                    let _payload:model.db_hardware_OutputI2cModulesModel = {List:payload[0]}
                    HardwareDBStorage.SetOutputI2cModules(false,_payload)
                    Storage.write({...Storage.read(),session:{OnRemoteChangesTimestamp:dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss")}})
                }
                if(head.contentType == SocketIORequestType_Hardware.SetLowDB_Hardware_InputI2cModules){
                    let _payload:model.db_hardware_InputI2cModulesModel = {List:payload[0]}
                    HardwareDBStorage.SetInputI2cModules(false,_payload)
                    Storage.write({...Storage.read(),session:{OnRemoteChangesTimestamp:dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss")}})
                }
                OnRemoteChanges();
            }
            
        },
        ()=>{ OnResponseWaiting(); /* OnResponseWaiting */},
        ()=>{ OnResponseArrived(); /* OnResponseArrived */},
        ()=>{ OnResponseError(); /* OnResponseError */},
        ()=>{ OnUserCredentialsError(); /* OnUserCredentialsError */},
        ()=>{ OnUserWithoutRightsError(); /* OnUserWithoutRightsError */},
        data?.db?.local.isLocal || false,
        data?.db?.local?.network?.local_ip  || '',env.REACT_APP_LOCAL_SERVER_PORT  || 0,
        env.REACT_APP_REMOTE_SERVER_IP || '',env.REACT_APP_REMOTE_SERVER_PORT || 0,
        data?.db?.local.remote_roomName_hashSecret,
        data?.db?.local.user.email,data?.db?.local.user.pwd);
    return elem;
}


export function instance_initialize(
    OnRemoteChanges:()=>void,

    //Callbacks gerais do socket-io:
    OnResponseWaiting:()=>void,
    OnResponseArrived:()=>void,
    OnResponseError:()=>void,

    OnUserCredentialsError:()=>void,
    OnUserWithoutRightsError:()=>void,
    ) {
    initialize_Storage();
    api = initialize_api();
    socket = initialize_SocketIO(OnRemoteChanges,OnResponseWaiting,OnResponseArrived,OnResponseError,OnUserCredentialsError,OnUserWithoutRightsError);

    console.log("Initialized with sucess!")
}

export function instance_start() {
    //start_a();
}
export function instance_kill() {
    //kill_a();
}