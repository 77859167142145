import React, {useState,useContext, useCallback, useEffect} from 'react';
import { IonModal, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Tab3.css';

import {Item,ItemDivider,ItemListHeader} from '../components/ionic/item/item'
import {ToggleItem} from '../components/ionic/button/button'
import {Toast} from '../components/ionic/alert/alert'
import {ItemSelect} from '../components/ionic/select/select'
import {Input,Textarea} from '../components/ionic/input/input'

import { AppContext } from '../components/storage/storage.react';

import {LocalDBStorage} from '../components/storage/CRUD/storage.local.db'
import {UserDBStorage} from '../components/storage/CRUD/storage.user.list.db'
import {LocationsDBStorage} from '../components/storage/CRUD/storage.location.list.db'
import {HardwareDBStorage} from '../components/storage/CRUD/storage.hardware.db'

import {UserModel as UserModel_FromNodeJSLocalServer} from '../resources/app/imported-from-nodejs-local-server-models'
import {LocalModel as LocalModel_FromNodeJSLocalServer} from '../resources/app/imported-from-nodejs-local-server-models'
import {OutputI2cModulesModel,InputI2cModulesModel,OutputDigitalListModel,InputDigitalListModel} from '../resources/app/imported-from-nodejs-local-server-models'

import {dataModel} from '../components/storage/model'

const Tab3: React.FC = () => {
  let [modalIsSet,setModal] = useState<string | null>(null)
  let [modalTitle,setModalTitle] = useState<string | null>(null)
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle >Tab 3</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Configurações</IonTitle>
          </IonToolbar>
        </IonHeader>

        <ItemDivider title="Geral" color="primary"></ItemDivider>

        <Item click={() => setModal(ConfigModalEnum.ConfigModal_Preferences)} title="Preferencias" button={true} detail={true}></Item>
        <Item click={() => setModal(ConfigModalEnum.ConfigModal_Network)} title="Network" button={true} detail={true}></Item>
        <Item click={() => setModal(ConfigModalEnum.ConfigModal_User)} title="Usuário" button={true} detail={true}></Item>

        <ItemDivider title="Cadastro de Usuários" color="primary"></ItemDivider>
        <Item click={() => setModal(ConfigModalEnum.ConfigModal_UserList)} title="Usuários" button={true} detail={true}></Item>

        <ItemDivider title="Cadastro de Locais" color="primary"></ItemDivider>
        <Item click={() => setModal(ConfigModalEnum.ConfigModal_LocalList)} title="Locais" button={true} detail={true}></Item>

        <ItemDivider title="Configuração de Hardware" color="primary"></ItemDivider>
        <Item click={() => setModal(ConfigModalEnum.ConfigModal_Hardware)} title="Configuração de Hardware" button={true} detail={true}></Item>
        
        {/* IonModal->onDidDismiss só é utilizado em dispositivo grande(computadores..) onde o backdropDismiss é visivel */}
        <IonModal isOpen={modalIsSet?true:false}  onDidDismiss={()=>{setModal(null);setModalTitle(null);}}>
          
            <IonHeader>
              <IonToolbar>
                <IonTitle >{modalTitle}</IonTitle>
                <IonButtons slot='end'><IonButton onClick={()=>{setModal(null);setModalTitle(null);}}>Fechar</IonButton></IonButtons>
              </IonToolbar>
            </IonHeader>
          
            <IonContent>
              <IonHeader collapse="condense">
                <IonToolbar>
                  <IonTitle size="large">{modalTitle}</IonTitle>
                </IonToolbar>
              </IonHeader>
              {
                modalIsSet == ConfigModalEnum.ConfigModal_Preferences ?
                <ConfigModal_Preferences getTitle={(title)=>{setModalTitle(title)}}></ConfigModal_Preferences>
                : null
              }
              {
                modalIsSet == ConfigModalEnum.ConfigModal_Network ?
                <ConfigModal_Network getTitle={(title)=>{setModalTitle(title)}}></ConfigModal_Network>
                : null
              }
              {
                modalIsSet == ConfigModalEnum.ConfigModal_User ?
                <ConfigModal_User getTitle={(title)=>{setModalTitle(title)}}></ConfigModal_User>
                : null
              }
              {
                modalIsSet == ConfigModalEnum.ConfigModal_UserList ?
                <ConfigModal_UserList getTitle={(title)=>{setModalTitle(title)}}></ConfigModal_UserList>
                : null
              }
              {
                modalIsSet == ConfigModalEnum.ConfigModal_LocalList ?
                <ConfigModal_LocalList getTitle={(title)=>{setModalTitle(title)}}></ConfigModal_LocalList>
                : null
              }
              {
                modalIsSet == ConfigModalEnum.ConfigModal_Hardware ?
                <ConfigModal_Hardware getTitle={(title)=>{setModalTitle(title)}}></ConfigModal_Hardware>
                : null
              }
            </IonContent>
         
        </IonModal>

      </IonContent>
    </IonPage>
  );
};

export default Tab3;

enum ConfigModalEnum{
  ConfigModal_Preferences='ConfigModal_Preferences',
  ConfigModal_Network='ConfigModal_Network',
  ConfigModal_User='ConfigModal_User',
  ConfigModal_Local='ConfigModal_Local',
  ConfigModal_UserList='ConfigModal_UserList',
  ConfigModal_LocalList='ConfigModal_LocalList',
  ConfigModal_Hardware='ConfigModal_Hardware',
}

interface ConfigModalPage{
  getTitle:(title: string) => void;
}

const ConfigModal_Preferences = (props:ConfigModalPage) => {
  const title = 'Preferencias';
  useEffect(() => {
    props.getTitle(title);
  }, []);
  //@ts-ignore
  const { state, dispatch } = useContext(AppContext);
  function refresh(){dispatch({type: 'refresh',payload: null})}

  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState<string>('');

  const [homeName, setHomeName] = useState<string>(LocalDBStorage.LocalBoardName());
  const [homeListName, setHomeListName] = useState<Array<string>>(LocalDBStorage.LocalBoardNameList());
  const [boardIndex, setBoardIndex] = useState<number>(LocalDBStorage.Preferences().BoardIndex);

  const refreshBoardInfo = useCallback(async () => {
    setHomeName(LocalDBStorage.LocalBoardName());
    setBoardIndex(LocalDBStorage.Preferences().BoardIndex);
  }, []);

  useEffect(() => {
    refreshBoardInfo();
    return ()=>{console.log('Component closed!');}
  }, [state]);

  useEffect(() => {
    return ()=>{console.log('Component closed!');}
  }, [boardIndex]);

  return(
    <>
    <ItemDivider title="Minhas residencias atual"></ItemDivider>
    <Input debounce={1000} label='Nome' type="text" value={homeName!} change={(data)=>{setHomeName(data);setToastMsg(`Nome alterado: ${data}`);setShowToast(true);LocalDBStorage.setHomeName(data);setHomeListName(LocalDBStorage.LocalBoardNameList());refresh();}}></Input>
    
    <ItemDivider title="Troque de ambiente do App"></ItemDivider>
    <ItemSelect label='Residencias' list={homeListName} value={boardIndex} onChange={(index)=>{LocalDBStorage.ChangeBoard(index);refresh();}}></ItemSelect>

    <Toast duration={3000} onDidDismiss={() => setShowToast(false)} isOpen={showToast} message={toastMsg}></Toast>
    </>
  )
}

const ConfigModal_Network = (props:ConfigModalPage) => {
  const title = 'Network';
  useEffect(() => {
    props.getTitle(title);
  }, []);
  //@ts-ignore
  const { state, dispatch } = useContext(AppContext);
  function refresh(){dispatch({type: 'refresh',payload: null})}

  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState<string>('');

  const [useNetworkData, setUseNetworkData] = useState<boolean>(LocalDBStorage.isRemote());
  const [localIp, setLocalIp] = useState<string>(LocalDBStorage.LocalDB().network.local_ip);
  const [localPort, setLocalPort] = useState<number>(LocalDBStorage.LocalDB().network.local_port);
  const [remoteIp, setRemoteIp] = useState<string>(LocalDBStorage.LocalDB().network.remote_ip);
  const [remotePort, setRemotePort] = useState<number>(LocalDBStorage.LocalDB().network.remote_port);
  const [room, setRoom] = useState<string>(LocalDBStorage.LocalDB().remote_roomName_hashSecret);
  
  return(
    <>
    <ItemDivider title="Uso de dados"></ItemDivider>
    <ToggleItem checked={useNetworkData} onChange={(val)=>{setUseNetworkData(val);if(val){LocalDBStorage.setRemote();setToastMsg('Você esta online!');setShowToast(true);}else{LocalDBStorage.setLocal();setToastMsg('Você esta offline!');setShowToast(true);};refresh();}} color="primary" label="Habilitar"></ToggleItem>

    <ItemDivider title="Conexão Local"></ItemDivider>
    <Input debounce={1000} label='IP Local' type="text" value={localIp!} change={(data)=>{setLocalIp(data);setToastMsg(`IP local alterado: ${data}`);setShowToast(true);LocalDBStorage.setLocalIP(data);refresh();}}></Input>
    <Input debounce={1000} label='Porta Local' type="number" value={localPort!} change={(data)=>{setLocalPort(parseInt(data));setToastMsg(`Porta local alterada: ${data}`);setShowToast(true);LocalDBStorage.setLocalPort(parseInt(data));refresh();}}></Input>

    <ItemDivider title="Conexão Remota"></ItemDivider>
    <Input debounce={1000} label='IP Remoto' type="text" value={remoteIp!} change={(data)=>{setRemoteIp(data);setToastMsg(`IP remoto alterado: ${data}`);setShowToast(true);LocalDBStorage.setRemoteIP(data);refresh();}}></Input>
    <Input debounce={1000} label='Porta Remota' type="number" value={remotePort!} change={(data)=>{setRemotePort(parseInt(data));setToastMsg(`Porta remota alterada: ${data}`);setShowToast(true);LocalDBStorage.setRemotePort(parseInt(data));refresh();}}></Input>

    <Textarea disabled={true} label="ID do produto" value={room}></Textarea>
    <Toast duration={3000} onDidDismiss={() => setShowToast(false)} isOpen={showToast} message={toastMsg}></Toast>
    </>
  )
}

const ConfigModal_User = (props:ConfigModalPage) => {
  const title = 'Usuário';
  useEffect(() => {
    props.getTitle(title);
  }, []);
  //@ts-ignore
  const { state, dispatch } = useContext(AppContext);
  function refresh(){dispatch({type: 'refresh',payload: null})}

  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState<string>('');

  const [userEmail, setUserEmail] = useState<string>(LocalDBStorage.LocalDB().user.email);
  const [userPwd, setUserPwd] = useState<string>(LocalDBStorage.LocalDB().user.pwd);

  return(
    <>
    <ItemDivider title="Conta do Usuário"></ItemDivider>

    <Input debounce={1000} label='Usuário' type="email" value={userEmail!} change={(data)=>{setUserEmail(data);setToastMsg(`Usuario alterado: ${data}`);setShowToast(true);LocalDBStorage.setUser(data);refresh();}}></Input>
    <Input debounce={1000} label='Senha' type="password" value={userPwd!} change={(data)=>{setUserPwd(data);setToastMsg(`Senha alterada`);setShowToast(true);LocalDBStorage.setUserPwd(data);refresh();}}></Input>

    <Toast duration={3000} onDidDismiss={() => setShowToast(false)} isOpen={showToast} message={toastMsg}></Toast>
    </>
  )
}

const ConfigModal_UserList = (props:ConfigModalPage) => {
  //Colocamos o titulo da pagina no primeiro carregamento: --->
  const title = 'Lista de Usuários';
  useEffect(() => {
    props.getTitle(title);
    return ()=>{console.log('Component closed!');}
  }, []);
  //<---
  
  //Declaração dos useState: --->
  const [toast, setToast] = useState({show:false,msg:''});
  const [userList, setUserList] = useState<Array<UserModel_FromNodeJSLocalServer>>([]);
  const [externalChange, setExternalChange] = useState<boolean>(false); //---> useState p/ indicar alterações externa ao componente(Ex: Componente não foi alterado ao clicar no 'ToggleItem' e sim por uma mudança externa do seu estado)
  function isExternalChange(){let prevValue = externalChange;setExternalChange(false);return prevValue == true;}
  const [remoteChange, setRemoteChange] = useState<boolean>(false); //---> useState p/ indicar alterações remotas(Ex: Componente foi alterado por outro usuário ou outro celular/computador)
  function isRemoteChange(){let prevValue = remoteChange;setRemoteChange(false);console.warn('isRemoteChange:',prevValue);return prevValue == true;}
  //<---

  //Declaramos 'state, dispatch' embora so disparamos o metodo refresh() para notificar o redux.
  //É preferivel acesso/alteração diretamente a classes que fazem interface com o Storage(UserDBStorage..): --->
  const { state, dispatch } = useContext(AppContext);
  function refresh(){dispatch({type:'refresh',payload: null})}
  //<---

  //Monitoramos se algum outro dispositivo(remoto) alterou os dados. Codigo tbem é executado na primeira vez(OnExternalChangesTimestamp) : --->
  async function OnRemoteChange(){
    let data = (await UserDBStorage.GetUsers(false)).List
    if(data){setUserList(data)}
  }
  useEffect(() => {
    setRemoteChange(true);
    OnRemoteChange();
  }, [state?.session.OnRemoteChangesTimestamp]);
  //<---

  //Busca(Fetch) dados do server no primeiro carregamento da pagina: --->
  const fetchDataFromServer = useCallback(async () => {
    try {
      let data: Array<UserModel_FromNodeJSLocalServer> = (await UserDBStorage.GetUsers(true)).List
      if(data){setUserList(data)}
    } catch (error) {
      let data = (await UserDBStorage.GetUsers()).List
      if(data){setUserList(data)}
    }
    setExternalChange(false);
    setRemoteChange(false);
  }, []);
  useEffect(() => {
    fetchDataFromServer();
  }, []);
  //<---

  //Se tiver erro na requisição ao server, então ignoramos a edição do usuário e usamos os dados offline: --->
  const OnNetworkError = useCallback(async () => {
    let data = (await UserDBStorage.GetUsers(false)).List
      if(data){setUserList(data)}
  }, []);
  useEffect(() => {
    setExternalChange(true);
    OnNetworkError();
  }, [state?.session.OnNetworkErrorTimestamp]);
  //<---
  
  //Send data to server: --->
  async function sendDataToServer_AndFetchNewData_SetUserList(list:Array<UserModel_FromNodeJSLocalServer>){
    if(isRemoteChange()){return;}
    await UserDBStorage.SetUsers(true,{List:list})
    refresh();
  }
  //<---

  //Edita um elemento localmente: --->
  function editElem(index:number,newElem:UserModel_FromNodeJSLocalServer){
    let newList = userList; newList[index] = newElem;
    setUserList(newList);
    return newList;
  }
  //<---

  return(
    <>
    <ItemDivider title="Lista de Usuários"></ItemDivider>
    {
      userList.map((elem,index,arr)=>{
        return(
          <div key={index}>
            <ItemDivider color="secondary" title={"Usuário "+elem.ID}></ItemDivider>

            <ToggleItem checked={elem.Enabled} onChange={(val)=>{
              if(isExternalChange()){return;}
              let newList = editElem(index,{...elem,Enabled:val});
              if(val){sendDataToServer_AndFetchNewData_SetUserList(newList);setToast({show:true,msg:`Usuário ${elem.ID} habilidado`});}
              else{sendDataToServer_AndFetchNewData_SetUserList([...newList]);setToast({show:true,msg:`Usuário ${elem.ID} desabilitado`});};
              }} color="primary" label="Habilitar"></ToggleItem>

              <Input debounce={1000} label='Nome' type="email" value={elem.Name}
              disabled={!elem.Enabled}
              change={(data)=>{
                if(isExternalChange()){return;}
                let newList = editElem(index,{...elem,Name:data});
                setToast({show:true,msg:`Nome do usuário ${elem.ID} alterado para ${data}`});
                sendDataToServer_AndFetchNewData_SetUserList(newList);
              }}></Input>

              <Input debounce={1000} label='Senha' type="password" value={elem.Pwd} //Pq isso tava assim: '${elem.Name}' ?
              disabled={!elem.Enabled}
              change={(data)=>{
                if(isExternalChange()){return;}
                let newList = editElem(index,{...elem,Pwd:data});
                setToast({show:true,msg:`Senha do usuário ${elem.ID} alterada`});
                sendDataToServer_AndFetchNewData_SetUserList(newList);
              }}></Input>

              <ToggleItem disabled={!elem.Enabled} checked={elem.IsAdmin} onChange={(val)=>{
                if(isExternalChange()){return;}
                let newList = editElem(index,{...elem,IsAdmin:val});
                if(val){sendDataToServer_AndFetchNewData_SetUserList(newList);setToast({show:true,msg:`Usuário ${elem.ID} agora é um administrador`});}
                else{sendDataToServer_AndFetchNewData_SetUserList([...newList]);setToast({show:true,msg:`Usuário ${elem.ID} agora não é mais administrador`});};
                }} color="primary" label="Administrador"></ToggleItem>

          </div>
        )
      })
    }
    <Toast duration={3000} onDidDismiss={() => setToast({show:false,msg:''})} isOpen={toast.show} message={toast.msg}></Toast>
    </>
  )
}

const ConfigModal_LocalList = (props:ConfigModalPage) => {
  //Colocamos o titulo da pagina no primeiro carregamento: --->
  const title = 'Lista de Locais';
  useEffect(() => {
    props.getTitle(title);
    return ()=>{console.log('Component closed!');}
  }, []);
  //<---
  
  //Declaração dos useState: --->
  const [toast, setToast] = useState({show:false,msg:''});
  const [localList, setLocalList] = useState<Array<LocalModel_FromNodeJSLocalServer>>([]);
  const [externalChange, setExternalChange] = useState<boolean>(false); //---> useState p/ indicar alterações externa ao componente(Ex: Componente não foi alterado ao clicar no 'ToggleItem' e sim por uma mudança externa do seu estado)
  function isExternalChange(){let prevValue = externalChange;setExternalChange(false);return prevValue == true;}
  const [remoteChange, setRemoteChange] = useState<boolean>(false); //---> useState p/ indicar alterações remotas(Ex: Componente foi alterado por outro usuário ou outro celular/computador)
  function isRemoteChange(){let prevValue = remoteChange;setRemoteChange(false);console.warn('isRemoteChange:',prevValue);return prevValue == true;}
  //<---

  //Declaramos 'state, dispatch' embora so disparamos o metodo refresh() para notificar o redux.
  //É preferivel acesso/alteração diretamente a classes que fazem interface com o Storage(UserDBStorage..): --->
  const { state, dispatch } = useContext(AppContext);
  function refresh(){dispatch({type:'refresh',payload: null})}
  //<---

  //Monitoramos se algum outro dispositivo(remoto) alterou os dados. Codigo tbem é executado na primeira vez(OnExternalChangesTimestamp) : --->
  async function OnRemoteChange(){
    let data = (await LocationsDBStorage.GetLocationList(false)).List
    if(data){setLocalList(data)}
  }
  useEffect(() => {
    setRemoteChange(true);
    OnRemoteChange();
  }, [state?.session.OnRemoteChangesTimestamp]);
  //<---

  //Busca(Fetch) dados do server no primeiro carregamento da pagina: --->
  const fetchDataFromServer = useCallback(async () => {
    try {
      let data: Array<LocalModel_FromNodeJSLocalServer> = (await LocationsDBStorage.GetLocationList(true)).List
      if(data){setLocalList(data)}
    } catch (error) {
      let data = (await LocationsDBStorage.GetLocationList()).List
      if(data){setLocalList(data)}
    }
    setExternalChange(false);
    setRemoteChange(false);
  }, []);
  useEffect(() => {
    fetchDataFromServer();
  }, []);
  //<---

  //Se tiver erro na requisição ao server, então ignoramos a edição do usuário e usamos os dados offline: --->
  const OnNetworkError = useCallback(async () => {
    let data = (await LocationsDBStorage.GetLocationList(false)).List
      if(data){setLocalList(data)}
  }, []);
  useEffect(() => {
    setExternalChange(true);
    OnNetworkError();
  }, [state?.session.OnNetworkErrorTimestamp]);
  //<---
  
  //Send data to server: --->
  async function sendDataToServer_AndFetchNewData_SetLocationList(list:Array<LocalModel_FromNodeJSLocalServer>){
    if(isRemoteChange()){return;}
    await LocationsDBStorage.SetLocationList(true,{List:list})
    refresh();
  }
  //<---

  //Edita um elemento localmente: --->
  function editElem(index:number,newElem:LocalModel_FromNodeJSLocalServer){
    let newList = localList; newList[index] = newElem;
    setLocalList(newList);
    return newList;
  }
  //<---

  return(
    <>
    <ItemDivider title="Lista de Locais"></ItemDivider>
    {
      localList.map((elem,index,arr)=>{
        return(
          <div key={index}>
            <ItemDivider color="secondary" title={"Local "+elem.ID}></ItemDivider>

            <ToggleItem checked={elem.Hidden} onChange={(val)=>{
              if(isExternalChange()){return;}
              let newList = editElem(index,{...elem,Hidden:val});
              if(val){sendDataToServer_AndFetchNewData_SetLocationList(newList);setToast({show:true,msg:`Local ${elem.ID} visível`});}
              else{sendDataToServer_AndFetchNewData_SetLocationList([...newList]);setToast({show:true,msg:`Local ${elem.ID} escondido`});};
              }} color="primary" label="Visível"></ToggleItem>

              <Input debounce={1000} label='Nome' type="text" value={elem.Name}
              disabled={!elem.Hidden}
              change={(data)=>{
                if(isExternalChange()){return;}
                let newList = editElem(index,{...elem,Name:data});
                setToast({show:true,msg:`Nome do local ${elem.ID} alterado para ${data}`});
                sendDataToServer_AndFetchNewData_SetLocationList(newList);
              }}></Input>

          </div>
        )
      })
    }
    <Toast duration={3000} onDidDismiss={() => setToast({show:false,msg:''})} isOpen={toast.show} message={toast.msg}></Toast>
    </>
  )
}

enum ConfigHardwareModalEnum{
  ConfigModal_Hardware_OutputI2cModules='ConfigModal_Hardware_OutputI2cModules',
  ConfigModal_Hardware_InputI2cModulesList='ConfigModal_Hardware_InputI2cModulesList',
  ConfigModal_Hardware_OutputDigitalList='ConfigModal_Hardware_OutputDigitalList',
}

const ConfigModal_Hardware = (props:ConfigModalPage) => {
  //Colocamos o titulo da pagina no primeiro carregamento: --->
  const title = 'Configuração de Hardware';
  useEffect(() => {
    props.getTitle(title);
    return ()=>{console.log('Component closed!');}
  }, []);
  //<---

  let [modalIsSet,setModal] = useState<string | null>(null)
  let [modalTitle,setModalTitle] = useState<string | null>(null)

  return(
    <>
    <ItemDivider title="Configurações de Hardware" color="primary"></ItemDivider>

    <Item click={() => setModal(ConfigHardwareModalEnum.ConfigModal_Hardware_OutputI2cModules)} title="Expansão I2C de Saída" button={true} detail={true}></Item>
    <Item click={() => setModal(ConfigHardwareModalEnum.ConfigModal_Hardware_InputI2cModulesList)} title="Expansão I2C de Entrada" button={true} detail={true}></Item>
    <Item click={() => setModal(ConfigHardwareModalEnum.ConfigModal_Hardware_OutputDigitalList)} title="Saídas Digitais" button={true} detail={true}></Item>

    <IonModal isOpen={modalIsSet?true:false}  onDidDismiss={()=>{setModal(null);setModalTitle(null);}}>
          
            <IonHeader>
              <IonToolbar>
                <IonTitle >{modalTitle}</IonTitle>
                <IonButtons slot='end'><IonButton onClick={()=>{setModal(null);setModalTitle(null);}}>Fechar</IonButton></IonButtons>
              </IonToolbar>
            </IonHeader>
          
            <IonContent>
              <IonHeader collapse="condense">
                <IonToolbar>
                  <IonTitle size="large">{modalTitle}</IonTitle>
                </IonToolbar>
              </IonHeader>
              {
                modalIsSet == ConfigHardwareModalEnum.ConfigModal_Hardware_OutputI2cModules ?
                <ConfigModal_Hardware_OutputI2cModules getTitle={(title)=>{setModalTitle(title)}}></ConfigModal_Hardware_OutputI2cModules>
                : null
              }
              {
                modalIsSet == ConfigHardwareModalEnum.ConfigModal_Hardware_InputI2cModulesList ?
                <ConfigModal_Hardware_InputI2cModulesList getTitle={(title)=>{setModalTitle(title)}}></ConfigModal_Hardware_InputI2cModulesList>
                : null
              }
              {
                modalIsSet == ConfigHardwareModalEnum.ConfigModal_Hardware_OutputDigitalList ?
                <ConfigModal_Hardware_OutputDigitalList getTitle={(title)=>{setModalTitle(title)}}></ConfigModal_Hardware_OutputDigitalList>
                : null
              }
            </IonContent>
         
        </IonModal>
    </>
  )
}
const ConfigModal_Hardware_OutputI2cModules = (props:ConfigModalPage) => {
  //Colocamos o titulo da pagina no primeiro carregamento: --->
  const title = 'Expansão I2C de Saída';
  useEffect(() => {
    props.getTitle(title);
    return ()=>{console.log('Component closed!');}
  }, []);
  //<---
  
  //Declaração dos useState: --->
  const [toast, setToast] = useState({show:false,msg:''});
  const [OutputI2cModulesList, setOutputI2cModulesList] = useState<Array<OutputI2cModulesModel>>([]);
  const [externalChange, setExternalChange] = useState<boolean>(false); //---> useState p/ indicar alterações externa ao componente(Ex: Componente não foi alterado ao clicar no 'ToggleItem' e sim por uma mudança externa do seu estado)
  function isExternalChange(){let prevValue = externalChange;setExternalChange(false);return prevValue == true;}
  const [remoteChange, setRemoteChange] = useState<boolean>(false); //---> useState p/ indicar alterações remotas(Ex: Componente foi alterado por outro usuário ou outro celular/computador)
  function isRemoteChange(){let prevValue = remoteChange;setRemoteChange(false);console.warn('isRemoteChange:',prevValue);return prevValue == true;}
  //<---

  //Declaramos 'state, dispatch' embora so disparamos o metodo refresh() para notificar o redux.
  //É preferivel acesso/alteração diretamente a classes que fazem interface com o Storage(UserDBStorage..): --->
  const { state, dispatch } = useContext(AppContext);
  function refresh(){dispatch({type:'refresh',payload: null})}
  //<---

  //Monitoramos se algum outro dispositivo(remoto) alterou os dados. Codigo tbem é executado na primeira vez(OnExternalChangesTimestamp) : --->
  async function OnRemoteChange(){
    let data = (await HardwareDBStorage.GetOutputI2cModules(false)).List
    if(data){setOutputI2cModulesList(data)}
  }
  useEffect(() => {
    setRemoteChange(true);
    OnRemoteChange();
  }, [state?.session.OnRemoteChangesTimestamp]);
  //<---

  //Busca(Fetch) dados do server no primeiro carregamento da pagina: --->
  const fetchDataFromServer = useCallback(async () => {
    try {
      let data: Array<OutputI2cModulesModel> = (await HardwareDBStorage.GetOutputI2cModules(true)).List
      if(data){setOutputI2cModulesList(data)}
    } catch (error) {
      let data = (await HardwareDBStorage.GetOutputI2cModules()).List
      if(data){setOutputI2cModulesList(data)}
    }
    setExternalChange(false);
    setRemoteChange(false);
  }, []);
  useEffect(() => {
    fetchDataFromServer();
  }, []);
  //<---

  //Se tiver erro na requisição ao server, então ignoramos a edição do usuário e usamos os dados offline: --->
  const OnNetworkError = useCallback(async () => {
    let data = (await HardwareDBStorage.GetOutputI2cModules(false)).List
    if(data){setOutputI2cModulesList(data)}
  }, []);
  useEffect(() => {
    setExternalChange(true);
    OnNetworkError();
  }, [state?.session.OnNetworkErrorTimestamp]);
  //<---
  
  //Send data to server: --->
  async function sendDataToServer_AndFetchNewData_SetOutputI2cModules(list:Array<OutputI2cModulesModel>){
    if(isRemoteChange()){return;}
    await HardwareDBStorage.SetOutputI2cModules(true,{List:list})
    refresh();
  }
  //<---

  //Edita um elemento localmente: --->
  function editElem_OutputI2cModulesModel(index:number,newElem:OutputI2cModulesModel){
    let newList = OutputI2cModulesList; newList[index] = newElem;
    setOutputI2cModulesList(newList);
    return newList;
  }
  //<---

  return(
    <>
    <ItemDivider color="tertiary" title="Lista I2COutput"></ItemDivider>
    {
      OutputI2cModulesList.map((elem,index,arr)=>{
        return(
          <div key={index}>
            <ItemDivider color="light" title={"I2COutput "+elem.ID}></ItemDivider>

            <ToggleItem checked={elem.Enabled} onChange={(val)=>{
              if(isExternalChange()){return;}
              let newList = editElem_OutputI2cModulesModel(index,{...elem,Enabled:val});
              if(val){sendDataToServer_AndFetchNewData_SetOutputI2cModules(newList);setToast({show:true,msg:`Endereço I2COutput ${elem.ID} habilitado`});}
              else{sendDataToServer_AndFetchNewData_SetOutputI2cModules([...newList]);setToast({show:true,msg:`Endereço I2COutput ${elem.ID} desabilitado`});};
              }} color="primary" label="Habilitar"></ToggleItem>

              <Input debounce={1000} label='Endereço' type="text" value={elem.Address}
              disabled={!elem.Enabled}
              change={(data)=>{
                if(isExternalChange()){return;}
                let newList = editElem_OutputI2cModulesModel(index,{...elem,Address:data});
                setToast({show:true,msg:`Endereço I2COutput ${elem.ID} alterado para ${data}`});
                sendDataToServer_AndFetchNewData_SetOutputI2cModules(newList);
              }}></Input>

          </div>
        )
      })
    }
    
    <Toast duration={3000} onDidDismiss={() => setToast({show:false,msg:''})} isOpen={toast.show} message={toast.msg}></Toast>
    </>
  )
}
const ConfigModal_Hardware_InputI2cModulesList = (props:ConfigModalPage) => {
  //Colocamos o titulo da pagina no primeiro carregamento: --->
  const title = 'Expansão I2C de Entrada';
  useEffect(() => {
    props.getTitle(title);
    return ()=>{console.log('Component closed!');}
  }, []);
  //<---
  
  //Declaração dos useState: --->
  const [toast, setToast] = useState({show:false,msg:''});
  const [InputI2cModulesList, setInputI2cModulesList] = useState<Array<InputI2cModulesModel>>([]);
  const [externalChange, setExternalChange] = useState<boolean>(false); //---> useState p/ indicar alterações externa ao componente(Ex: Componente não foi alterado ao clicar no 'ToggleItem' e sim por uma mudança externa do seu estado)
  function isExternalChange(){let prevValue = externalChange;setExternalChange(false);return prevValue == true;}
  const [remoteChange, setRemoteChange] = useState<boolean>(false); //---> useState p/ indicar alterações remotas(Ex: Componente foi alterado por outro usuário ou outro celular/computador)
  function isRemoteChange(){let prevValue = remoteChange;setRemoteChange(false);console.warn('isRemoteChange:',prevValue);return prevValue == true;}
  //<---

  //Declaramos 'state, dispatch' embora so disparamos o metodo refresh() para notificar o redux.
  //É preferivel acesso/alteração diretamente a classes que fazem interface com o Storage(UserDBStorage..): --->
  const { state, dispatch } = useContext(AppContext);
  function refresh(){dispatch({type:'refresh',payload: null})}
  //<---

  //Monitoramos se algum outro dispositivo(remoto) alterou os dados. Codigo tbem é executado na primeira vez(OnExternalChangesTimestamp) : --->
  async function OnRemoteChange(){
    let data = (await HardwareDBStorage.GetInputI2cModules(false)).List
    if(data){setInputI2cModulesList(data)}
  }
  useEffect(() => {
    setRemoteChange(true);
    OnRemoteChange();
  }, [state?.session.OnRemoteChangesTimestamp]);
  //<---

  //Busca(Fetch) dados do server no primeiro carregamento da pagina: --->
  const fetchDataFromServer = useCallback(async () => {
    try {
      let data: Array<InputI2cModulesModel> = (await HardwareDBStorage.GetInputI2cModules(true)).List
      if(data){setInputI2cModulesList(data)}

    } catch (error) {
      let data = (await HardwareDBStorage.GetInputI2cModules()).List
      if(data){setInputI2cModulesList(data)}
    }
    setExternalChange(false);
    setRemoteChange(false);
  }, []);
  useEffect(() => {
    fetchDataFromServer();
  }, []);
  //<---

  //Se tiver erro na requisição ao server, então ignoramos a edição do usuário e usamos os dados offline: --->
  const OnNetworkError = useCallback(async () => {
    let data = (await HardwareDBStorage.GetInputI2cModules(false)).List
    if(data){setInputI2cModulesList(data)}
  }, []);
  useEffect(() => {
    setExternalChange(true);
    OnNetworkError();
  }, [state?.session.OnNetworkErrorTimestamp]);
  //<---
  
  //Send data to server: --->
  async function sendDataToServer_AndFetchNewData_SetInputI2cModules(list:Array<InputI2cModulesModel>){
    if(isRemoteChange()){return;}
    await HardwareDBStorage.SetInputI2cModules(true,{List:list})
    refresh();
  }
  //<---

  //Edita um elemento localmente: --->
  function editElem_InputI2cModulesModel(index:number,newElem:InputI2cModulesModel){
    let newList = InputI2cModulesList; newList[index] = newElem;
    setInputI2cModulesList(newList);
    return newList;
  }
  //<---

  return(
    <>    
    <ItemDivider color="tertiary" title="Lista I2CInput"></ItemDivider>
    {
      InputI2cModulesList.map((elem,index,arr)=>{
        return(
          <div key={index}>
            <ItemDivider color="light" title={"I2CInput "+elem.ID}></ItemDivider>

            <ToggleItem checked={elem.Enabled} onChange={(val)=>{
              if(isExternalChange()){return;}
              let newList = editElem_InputI2cModulesModel(index,{...elem,Enabled:val});
              if(val){sendDataToServer_AndFetchNewData_SetInputI2cModules(newList);setToast({show:true,msg:`Endereço I2CInput ${elem.ID} habilitado`});}
              else{sendDataToServer_AndFetchNewData_SetInputI2cModules([...newList]);setToast({show:true,msg:`Endereço I2CInput ${elem.ID} desabilitado`});};
              }} color="primary" label="Habilitar"></ToggleItem>

              <Input debounce={1000} label='Endereço' type="text" value={elem.Address}
              disabled={!elem.Enabled}
              change={(data)=>{
                if(isExternalChange()){return;}
                let newList = editElem_InputI2cModulesModel(index,{...elem,Address:data});
                setToast({show:true,msg:`Endereço I2CInput ${elem.ID} alterado para ${data}`});
                sendDataToServer_AndFetchNewData_SetInputI2cModules(newList);
              }}></Input>

          </div>
        )
      })
    }
    
    <Toast duration={3000} onDidDismiss={() => setToast({show:false,msg:''})} isOpen={toast.show} message={toast.msg}></Toast>
    </>
  )
}
const ConfigModal_Hardware_OutputDigitalList = (props:ConfigModalPage) => {
  //Colocamos o titulo da pagina no primeiro carregamento: --->
  const title = 'Saídas Digitais';
  useEffect(() => {
    props.getTitle(title);
    return ()=>{console.log('Component closed!');}
  }, []);
  //<---
  
  //Declaração dos useState: --->
  const [toast, setToast] = useState({show:false,msg:''});
  const [OutputDigitalList, setOutputDigitalList] = useState<Array<OutputDigitalListModel>>([]);
  const [externalChange, setExternalChange] = useState<boolean>(false); //---> useState p/ indicar alterações externa ao componente(Ex: Componente não foi alterado ao clicar no 'ToggleItem' e sim por uma mudança externa do seu estado)
  function isExternalChange(){let prevValue = externalChange;setExternalChange(false);return prevValue == true;}
  const [remoteChange, setRemoteChange] = useState<boolean>(false); //---> useState p/ indicar alterações remotas(Ex: Componente foi alterado por outro usuário ou outro celular/computador)
  function isRemoteChange(){let prevValue = remoteChange;setRemoteChange(false);console.warn('isRemoteChange:',prevValue);return prevValue == true;}

  const [localList, setLocalList] = useState<Array<LocalModel_FromNodeJSLocalServer>>([]);
  //<---

  //Declaramos 'state, dispatch' embora so disparamos o metodo refresh() para notificar o redux.
  //É preferivel acesso/alteração diretamente a classes que fazem interface com o Storage(UserDBStorage..): --->
  const { state, dispatch } = useContext(AppContext);
  function refresh(){dispatch({type:'refresh',payload: null})}
  //<---

  //Monitoramos se algum outro dispositivo(remoto) alterou os dados. Codigo tbem é executado na primeira vez(OnExternalChangesTimestamp) : --->
  async function OnRemoteChange(){
    let data = (await HardwareDBStorage.GetOutputDigitalList(false)).List
    if(data){setOutputDigitalList(data)}
  }
  useEffect(() => {
    setRemoteChange(true);
    OnRemoteChange();
  }, [state?.session.OnRemoteChangesTimestamp]);
  //<---

  //Busca(Fetch) dados do server no primeiro carregamento da pagina: --->
  const fetchDataFromServer = useCallback(async () => {
    try {
      let data: Array<OutputDigitalListModel> = (await HardwareDBStorage.GetOutputDigitalList(true)).List
      if(data){setOutputDigitalList(data)}

      let _data = (await LocationsDBStorage.GetLocationList(false)).List
      if(_data){setLocalList(_data)}

    } catch (error) {
      let data = (await HardwareDBStorage.GetOutputDigitalList()).List
      if(data){setOutputDigitalList(data)}

    }
    setExternalChange(false);
    setRemoteChange(false);
  }, []);
  useEffect(() => {
    fetchDataFromServer();
  }, []);
  //<---

  //Se tiver erro na requisição ao server, então ignoramos a edição do usuário e usamos os dados offline: --->
  const OnNetworkError = useCallback(async () => {
    let data = (await HardwareDBStorage.GetOutputDigitalList(false)).List
    if(data){setOutputDigitalList(data)}

  }, []);
  useEffect(() => {
    setExternalChange(true);
    OnNetworkError();
  }, [state?.session.OnNetworkErrorTimestamp]);
  //<---
  
  //Send data to server: --->
  async function sendDataToServer_AndFetchNewData_SetOutputDigitalList(list:Array<OutputDigitalListModel>){
    if(isRemoteChange()){return;}
    await HardwareDBStorage.SetOutputDigitalList(true,{List:list})
    refresh();
  }
  //<---

  //Edita um elemento localmente: --->
  function editElem_OutputDigitalListModel(index:number,newElem:OutputDigitalListModel){
    let newList = OutputDigitalList; newList[index] = newElem;
    setOutputDigitalList(newList);
    return newList;
  }
  //<---

  return(
    <>
    <ItemDivider color="tertiary" title="Lista de Saidas Digitais"></ItemDivider>
    {
      OutputDigitalList.map((elem,index,arr)=>{
        return(
          <div key={index}>
            <ItemDivider color="light" title={"I2CInput "+elem.ID}></ItemDivider>

            <ToggleItem checked={elem.Enabled} onChange={(val)=>{
              if(isExternalChange()){return;}
              let newList = editElem_OutputDigitalListModel(index,{...elem,Enabled:val});
              if(val){sendDataToServer_AndFetchNewData_SetOutputDigitalList(newList);setToast({show:true,msg:`Saida ${elem.ID} habilitado`});}
              else{sendDataToServer_AndFetchNewData_SetOutputDigitalList([...newList]);setToast({show:true,msg:`Saida ${elem.ID} desabilitado`});};
              }} color="primary" label="Habilitar"></ToggleItem>

            <ToggleItem checked={elem.Hidden} onChange={(val)=>{
              if(isExternalChange()){return;}
              let newList = editElem_OutputDigitalListModel(index,{...elem,Hidden:val});
              if(val){sendDataToServer_AndFetchNewData_SetOutputDigitalList(newList);setToast({show:true,msg:`Saida ${elem.ID} visivel`});}
              else{sendDataToServer_AndFetchNewData_SetOutputDigitalList([...newList]);setToast({show:true,msg:`Saida ${elem.ID} escondida`});};
              }} color="primary" label="Visivel"></ToggleItem>

              <Input debounce={1000} label='Nome' type="text" value={elem.Name}
              disabled={!elem.Enabled}
              change={(data)=>{
                if(isExternalChange()){return;}
                let newList = editElem_OutputDigitalListModel(index,{...elem,Name:data});
                setToast({show:true,msg:`Nome da saida ${elem.ID} alterado para ${data}`});
                sendDataToServer_AndFetchNewData_SetOutputDigitalList(newList);
              }}></Input>

              <Input debounce={1000} label='Local' type="text" value={elem._Local_Name || ""}
              disabled={true || !elem.Enabled}
              change={(data)=>{
                if(isExternalChange()){return;}
                //let newList = editElem_OutputDigitalListModel(index,{...elem,Name:data});
                //setToast({show:true,msg:`Sala ${elem.ID} alterado para ${data}`});
                //sendDataToServer_AndFetchNewData_SetOutputDigitalList(newList);
              }}></Input>
              <ItemSelect label='Local' list={localList.map((val,index,arr)=>{return val.Name})} value={elem._Local_Index}
              onChange={(index)=>{
                // let room = localList[index];
                // let newList = editElem_OutputDigitalListModel(index,{...elem,Local_ID:room.ID});
                // setToast({show:true,msg:`Local do elemento ${elem.ID} alterado para ${room.Name}`});
                // sendDataToServer_AndFetchNewData_SetOutputDigitalList(newList);
              }}></ItemSelect>

          </div>
        )
      })
    }
    <Toast duration={3000} onDidDismiss={() => setToast({show:false,msg:''})} isOpen={toast.show} message={toast.msg}></Toast>
    </>
  )
}